import React, { useState } from 'react';
import { Row, Col } from 'antd';
import {
  getValid$Cost,
  getFixedCostWith$,
  getWirelessServRef,
} from '../../../common/common';
import {
  EECproductRemoveFromCart,
  EECproductAddToCart,
} from '../../../common/GTMScripts';
import {
  getServicePropsByCode,
  saveInternetPlan,
  saveVasPlanInfo,
  saveWirelessPlanInfo,
  setMBoxReqObj,
  setSelectedBundle,
} from '../../../store/action/OrderAction';
import { useDispatch } from 'react-redux';
import { useCartData, useMbox, useCallCenterFlag } from '../../../hooks/order';
import { notification } from 'antd';
import useWindowDimensions from '../../common/useWindowDimensions';
import { RenderTfnButton } from '../../Layout/RenderTfnButton';

const MobilePhonePlans = (props) => {
  const { isMobile } = useWindowDimensions();
  const dispatch = useDispatch();
  const { serviceabilityCheck } = props;
  const { mboxRequired } = useMbox();
  const { wirelessCartInfo, isBundleAdded, getMobilePrice } = useCartData();
  const { mobileProducts} = serviceabilityCheck;
  const [showMore, setShowMore] = useState(-1);
  const { wirelessPlanInfo = [] } = wirelessCartInfo;
  const { showCallNow } = useCallCenterFlag();

  const openNotification = (placement) => {
    notification.info({
      message: 'Maximum of 4 Plans per Cart.',
      placement,
    });
  };

  // save cart mobileplan to redux
  const addToCart = () => {
    dispatch(
      saveWirelessPlanInfo({
        wirelessPlanInfo: wirelessPlanInfo,
        wirelesServeRef: getWirelessServRef(wirelessPlanInfo),
      })
    );
  };

  // Wireless Inc button click
  const handleCounterInc = (item) => {
    if (wirelessPlanInfo.reduce((a, { quantity }) => a + quantity, 0) === 4) {
      openNotification('topRight');
      return;
    }
    const cartIndex = wirelessPlanInfo.findIndex(
      (plan) => plan.servRef === item.servRef
    );
    const cartItem = cartIndex !== -1 ? wirelessPlanInfo[cartIndex] : '';
    if ((item?.quantity || cartItem?.quantity) && cartItem) {
      cartItem.quantity = cartItem.quantity + 1 > 4 ? 4 : cartItem.quantity + 1;
      addToCart();
    } else {
      item.quantity = 1;
      wirelessPlanInfo.push(item);
      // Add to Mailbox required object
      dispatch(getServicePropsByCode(item.servCode)).then((res) => {
        mboxRequired[item.servCode] =
          res.action?.payload?.data?.item?.isMboxReq;
        dispatch(setMBoxReqObj(mboxRequired));
      });
      EECproductAddToCart(item);
      addToCart();
    }
  };

  // Wireless dec button click
  const handleCounterDec = (item) => {
    // let mboxReq = mboxRequired;
    const cartIndex = wirelessPlanInfo.findIndex(
      (plan) => plan.servRef === item.servRef
    );
    const cartItem = cartIndex !== -1 ? wirelessPlanInfo[cartIndex] : '';
    if (cartIndex !== -1 && (item?.quantity || cartItem?.quantity)) {
      wirelessPlanInfo[cartIndex].quantity =
        wirelessPlanInfo[cartIndex].quantity - 1 >= 1
          ? wirelessPlanInfo[cartIndex].quantity - 1
          : 0;
      if (cartIndex !== -1 && wirelessPlanInfo[cartIndex].quantity === 0) {
        wirelessPlanInfo.splice(cartIndex, 1);
        if (wirelessPlanInfo.length === 0) {
          delete mboxRequired[item.servCode];
        }
        EECproductRemoveFromCart(item);
        if (isBundleAdded) {
          dispatch(setSelectedBundle(null));
          dispatch(saveInternetPlan({}));
          dispatch(saveVasPlanInfo({
            vasPlan: [],
            vasProductCart: []
          }));
        }
      }
      addToCart();
    } else {
      addToCart();
      // Update isMailboxRequired accordingly
      if (wirelessPlanInfo.length === 0) {
        delete mboxRequired[item.servCode];
        dispatch(setMBoxReqObj(mboxRequired));
      }
    }
  };

  const getMobileProdPrice = (product, isAddedToCart) => {
    const mobileProductPrice = getMobilePrice(product, 'product');
    if (Object.keys(isAddedToCart).length > 0) {
      return getFixedCostWith$(parseFloat(isAddedToCart.quantity * mobileProductPrice));
    }
    return getValid$Cost(mobileProductPrice);
  };


  return (
    <>
      <div className="plan-heading d-flex al-center">
        <span className="y-icon">
          <i className="fa-solid fa-mobile-screen-button"></i>
        </span>{' '}
        <h3>
          (<span>{mobileProducts && mobileProducts.length}</span>) Mobile Phone
          Plan{`${mobileProducts && mobileProducts.length > 1 ? 's' : ''}`}{' '}
          Available
        </h3>
      </div>

      <div className="all-single-plans">
        <Row>
          {mobileProducts &&
            mobileProducts.map((product, i) => {
              // check if its added to cart
              let isAddedToCart =
                wirelessPlanInfo.find((e) => e.servRef === product.servRef) ||
                {};

              let learnMoreData = '';
              // LEARN MORE DATA
              learnMoreData =
                product &&
                product.serviceInfo
                  ?.split('.')
                  .filter((item) => item !== '')
                  .map((listItem, index) => {
                    if (index > 0) {
                      return (
                        <li key={listItem}>
                          <p>{listItem && listItem + '.'}</p>
                        </li>
                      );
                    }
                    return '';
                  });
              return (
                <Col span={8} className="plan-single" key={product.servRef}>
                  <div
                    className={`plan-single-inner  ${
                      showMore === i && showMore !== -1 ? 'Seeless' : ''
                    }`}
                  >
                    <h4>{product.serviceName.replace(' Plan', '')}</h4>
                    {/* <h5>{getValid$Cost(product.price)} / month</h5> */}
                    <ul>
                      <li className="first-description">
                        <p>{product.serviceInfo?.split('.')[0]}.</p>
                      </li>
                      {showMore === i && showMore !== -1 ? learnMoreData : null}
                    </ul>
                    <span
                      className="seemore"
                      onClick={() => {
                        setShowMore(showMore === i ? -1 : i);
                      }}
                    >
                      {' '}
                      {showMore === i && showMore !== -1
                        ? 'See Less'
                        : 'See More'}{' '}
                      <i
                        className={`fa-solid ${
                          showMore === i && showMore !== -1
                            ? 'fa-angle-up'
                            : 'fa-angle-down'
                        }`}
                      ></i>
                    </span>
                    {!showCallNow ? (
                      <div className="down-cart d-flex al-center j-between">
                        <div className="mobilephone-quantity">
                          <p>{isMobile ? '' : 'Quantity'}</p>
                          <div className="d-flex">
                            <button
                              onClick={() => {
                                handleCounterDec(product);
                              }}
                              disabled={Object.keys(isAddedToCart).length === 0}
                            >
                              <i className="fas fa-minus"></i>
                            </button>
                            <div className="counter-main">
                              {Object.keys(isAddedToCart).length > 0
                                ? isAddedToCart.quantity
                                : 0}
                            </div>
                            <button
                              onClick={() => {
                                handleCounterInc(product);
                              }}
                            >
                              <i className="fas fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div className="mobilepone-subtotal main-below-total">
                          <p>{isMobile ? '' : 'Subtotal'}</p>
                          <span className="total-single">{getMobileProdPrice(product, isAddedToCart)} / mo</span></div>
                      </div>
                    ) : (
                      <RenderTfnButton />
                    )}
                  </div>
                </Col>
              );
            })}
        </Row>
      </div>
    </>
  );
};

export default MobilePhonePlans;
