import React from 'react';
import { useAgentTfn} from '../../hooks/order';


export const RenderTfnButton = ({customTFN , product}) => {
  return (
    <div className="agent-call-button">
      <a href={`tel:${useAgentTfn(product)}`}
        className={`blue-button ${!customTFN && product !== 'whi' && 'elnk-dni-link'}`}>
        <i className="fa-solid fa-mobile-screen-button"></i>{' '}
        <span className={`phone-span ${!customTFN && product !== 'whi' && 'elnk-dni'}`}> {useAgentTfn(product)}</span>
      </a>
    </div>
  );
};

