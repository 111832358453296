import React, { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { routesConfig } from '../../util/util';
import { BackdropLoader } from '../common/Loader';

/**
 * List of partners with their respective acuid and Euid.
 */
const partners = {
  '9173': {
    prod: {
      acaid: 'CORDMEDI',
      Euid: '38ca01b3-f7f6-4303-bede-55957be28bd9',
    },
    lower: {
      acaid: 'CORDMEDI',
      Euid: '9772412a-48c3-4749-921a-245148854bf1',
    }
  }
}

export default function Partner() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { partnerId } = useParams();

  useEffect(() => {
    const env = window.location.host.includes('checkout.earthlink.com') ? 'prod' : 'lower';
    const { acaid, Euid } = partners?.[partnerId]?.[env] ?? {};
    const { addr1 = '', addr2 = '' , city = '', pubid = '' , state = '', zip = ''  } = queryString.parse(search);
    const addr = `${addr1} ${addr2}`.trim();
    const cpid = `${partnerId}-${pubid}`;

    if (!acaid || !Euid || !addr || !city || !state || !zip || !pubid) {
      return window.location.href = 'https://www.earthlink.net/';
    }

    return navigate(`${routesConfig.home}?acaddress=${addr}&accity=${city}&acstate=${state}&aczip=${zip}&acsid=${pubid}&acaid=${acaid}&Euid=${Euid}&cpid=${cpid}`)
  }, [partnerId, navigate]);

  return <BackdropLoader />;
}
