// AFFILIATE LOGIN
export const LOGIN = 'LOGIN'
export const LOGIN_PENDING = 'LOGIN_PENDING'
export const LOGIN_REJECTED = 'LOGIN_REJECTED'
export const LOGIN_FULFILLED = 'LOGIN_FULFILLED'
export const LOGOUT = 'LOGOUT'
export const UPDATE_TOKENS = 'UPDATE_TOKENS'

// EXPRESS CHECKOUT
// STEP 1
export const EXPRESS_VAS = 'EXPRESS_VAS'
export const EXPRESS_VAS_PENDING = 'EXPRESS_VAS_PENDING'
export const EXPRESS_VAS_REJECTED = 'EXPRESS_VAS_REJECTED'
export const EXPRESS_VAS_FULFILLED = 'EXPRESS_VAS_FULFILLED'
export const SET_EXPRESS_LOADING = 'SET_EXPRESS_LOADING'

// WIRELESS
export const SAVE_BUYFLOW = 'SAVE_BUYFLOW'
export const SAVE_WIRELESS_PLAN_INFO = 'SAVE_WIRELESS_PLAN_INFO'
export const CHECK_DEVICE_COMPATIBILITY = 'CHECK_DEVICE_COMPATIBILITY'
export const CHECK_DEVICE_COMPATIBILITY_PENDING = 'CHECK_DEVICE_COMPATIBILITY_PENDING'
export const CHECK_DEVICE_COMPATIBILITY_REJECTED = 'CHECK_DEVICE_COMPATIBILITY_REJECTED'
export const CHECK_DEVICE_COMPATIBILITY_FULFILLED = 'CHECK_DEVICE_COMPATIBILITY_FULFILLED'
export const CHECK_MOBILE_PRODUCTS = 'CHECK_MOBILE_PRODUCTS'
export const CHECK_MOBILE_PRODUCTS_PENDING = 'CHECK_MOBILE_PRODUCTS_PENDING'
export const CHECK_MOBILE_PRODUCTS_REJECTED = 'CHECK_MOBILE_PRODUCTS_REJECTED'
export const CHECK_MOBILE_PRODUCTS_FULFILLED = 'CHECK_MOBILE_PRODUCTS_FULFILLED'

// STEP 1.
export const SAVE_SERVICE_ADDRESS_INFO = 'SAVE_SERVICE_ADDRESS_INFO'
export const SAVE_PLAN_INFO = 'SAVE_PLAN_INFO'
export const SAVE_VAS_PLAN_INFO = 'SAVE_VAS_PLAN_INFO'
export const CHECK_SERVICEABILITY = 'CHECK_SERVICEABILITY'
export const CHECK_SERVICEABILITY_PENDING = 'CHECK_SERVICEABILITY_PENDING'
export const CHECK_SERVICEABILITY_REJECTED = 'CHECK_SERVICEABILITY_REJECTED'
export const CHECK_SERVICEABILITY_FULFILLED = 'CHECK_SERVICEABILITY_FULFILLED'
export const VALIDATE_ADDRESS = 'VALIDATE_ADDRESS'
export const VALIDATE_ADDRESS_FULFILLED = 'VALIDATE_ADDRESS_FULFILLED'
export const SERVICE_CHECK = 'SERVICE_CHECK'
export const SERVICE_CHECK_PENDING = 'SERVICE_CHECK_PENDING'
export const SERVICE_CHECK_REJECTED = 'SERVICE_CHECK_REJECTED'
export const SERVICE_CHECK_FULFILLED = 'SERVICE_CHECK_FULFILLED'
export const UPDATE_IS_BUSINESS_ADDRESS = 'UPDATE_IS_BUSINESS_ADDRESS'
export const SAVE_BUSINESS_INFO = 'SAVE_BUSINESS_INFO'
export const SCHEDULE_APPT_CHECK = 'SCHEDULE_APPT_CHECK'
export const SCHEDULE_APPT_CHECK_PENDING = 'SCHEDULE_APPT_CHECK_PENDING'
export const SCHEDULE_APPT_CHECK_REJECTED = 'SCHEDULE_APPT_CHECK_REJECTED'
export const SCHEDULE_APPT_CHECK_FULFILLED = 'SCHEDULE_APPT_CHECK_FULFILLED'
export const SAVE_SCHEDULE_DATE_TIME = 'SAVE_SCHEDULE_DATE_TIME'
export const SAVE_STEP_ONE = 'SAVE_STEP_ONE'
export const SET_RTS_VISIBLE = 'SET_RTS_VISIBLE'
export const SET_RTS_ATTEMPT_COUNT = 'SET_RTS_ATTEMPT_COUNT'


// STEP 3
export const ACCOUNT_CHECK = 'ACCOUNT_CHECK'
export const ACCOUNT_CHECK_PENDING = 'ACCOUNT_CHECK_PENDING'
export const ACCOUNT_CHECK_REJECTED = 'ACCOUNT_CHECK_REJECTED'
export const ACCOUNT_CHECK_FULFILLED = 'ACCOUNT_CHECK_FULFILLED'

// STEP 4
export const SAVE_CHALLENGE_QUESTION_INFO = 'SAVE_CHALLENGE_QUESTION_INFO'

// STEP 6
export const SAVE_PAYMENT_INFO = 'SAVE_PAYMENT_INFO'
export const SAVE_CHECKOUT_INFO = 'SAVE_CHECKOUT_INFO'

// Prior to review order
export const UPDATE_CUST_TC = 'UPDATE_CUST_TC'
export const UPDATE_CARD_ERROR = 'UPDATE_CARD_ERROR'

export const UPDATE_ERROR_MSG = 'UPDATE_ERROR_MSG'
// RESET STATE
export const RESET_STATE = 'RESET_STATE'

// Session
export const SESSION_CALL = 'SESSION_CALL'
export const SESSION_CALL_PENDING = 'SESSION_CALL_PENDING'
export const SESSION_CALL_REJECTED = 'SESSION_CALL_REJECTED'
export const SESSION_CALL_FULFILLED = 'SESSION_CALL_FULFILLED'


// ORDER SUBMISSION
export const INITIAL_ORDER_CREATE = 'INITIAL_ORDER_CREATE'
export const INITIAL_ORDER_CREATE_PENDING = 'INITIAL_ORDER_CREATE_PENDING'
export const INITIAL_ORDER_CREATE_REJECTED = 'INITIAL_ORDER_CREATE_REJECTED'
export const INITIAL_ORDER_CREATE_FULFILLED = 'INITIAL_ORDER_CREATE_FULFILLED'

export const UPDATE_ORDER = 'UPDATE_ORDER'
export const UPDATE_ORDER_PENDING = 'UPDATE_ORDER_PENDING'
export const UPDATE_ORDER_REJECTED = 'UPDATE_ORDER_REJECTED'
export const UPDATE_ORDER_FULFILLED = 'UPDATE_ORDER_FULFILLED'

export const VALIDATE_CARD = 'VALIDATE_CARD'
export const VALIDATE_CARD_PENDING = 'VALIDATE_CARD_PENDING'
export const VALIDATE_CARD_REJECTED = 'VALIDATE_CARD_REJECTED'
export const VALIDATE_CARD_FULFILLED = 'VALIDATE_CARD_FULFILLED'

export const SUBMIT_ORDER = 'SUBMIT_ORDER'
export const SUBMIT_ORDER_PENDING = 'SUBMIT_ORDER_PENDING'
export const SUBMIT_ORDER_REJECTED = 'SUBMIT_ORDER_REJECTED'
export const SUBMIT_ORDER_FULFILLED = 'SUBMIT_ORDER_FULFILLED'

export const RESET_SUBMIT_RESPONSE = 'RESET_SUBMIT_RESPONSE'

export const SAVE_PASSWORD = 'SAVE_PASSWORD'
export const SAVE_ADDRESS_IS_SAME = 'SAVE_ADDRESS_IS_SAME'
export const SET_TFN = 'SET_TFN'
export const SET_CUSTOM_TFN = 'SET_CUSTOM_TFN'
export const SET_CUSTOM_WHI_TFN = 'SET_CUSTOM_WHI_TFN'
export const SET_REFERRER = 'SET_REFERRER'
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT'

// Save optional Mailbox
export const SET_ELNK_MAILBOX_REQ = 'SET_ELNK_MAILBOX_REQ'
export const SET_MAILBOX_REQ = 'SET_MAILBOX_REQ'
export const GET_SERVICE_PROPS_BY_CODE = 'GET_SERVICE_PROPS_BY_CODE'
export const GET_SERVICE_PROPS_BY_CODE_FULFILLED = 'GET_SERVICE_PROPS_BY_CODE_FULFILLED';

export const GET_ACC_DOMAINS = 'GET_ACC_DOMAINS';
export const GET_ACC_DOMAINS_FULFILLED = 'GET_ACC_DOMAINS_FULFILLED';

export const SET_MOBILE_CONTAINER = 'SET_MOBILE_CONTAINER'

export const MOBILE_PRODUCTS = 'MOBILE_PRODUCTS'
export const MOBILE_PRODUCTS_PENDING = 'MOBILE_PRODUCTS_PENDING'
export const MOBILE_PRODUCTS_REJECTED = 'MOBILE_PRODUCTS_REJECTED'
export const MOBILE_PRODUCTS_FULFILLED = 'MOBILE_PRODUCTS_FULFILLED'
export const MAZAMA_WEBHOOK = 'MAZAMA_WEBHOOK'
export const SAVE_SPANISH_LANGUAGE_PREFERENCE = 'SAVE_SPANISH_LANGUAGE_PREFERENCE'

export const SAVE_TCPA_DISCLAIMER = 'SAVE_TCPA_DISCLAIMER'
export const SET_ACTIVE_PRODUCT_TAB = 'SET_ACTIVE_PRODUCT_TAB'
// Bundle Details

export const GET_BUNDLES_DETAILS = 'GET_BUNDLES_DETAILS'
export const GET_BUNDLES_DETAILS_PENDING = 'GET_BUNDLES_DETAILS_PENDING'
export const GET_BUNDLES_DETAILS_REJECTED = 'GET_BUNDLES_DETAILS_REJECTED'
export const GET_BUNDLES_DETAILS_FULFILLED = 'GET_BUNDLES_DETAILS_FULFILLED'
export const SET_BUNDLE_PRODUCT = 'SET_BUNDLE_PRODUCT';

export const SEND_BROADBAND = 'SEND_BROADBAND'
export const SEND_BROADBAND_PENDING = 'SEND_BROADBAND_PENDING'
export const SEND_BROADBAND_REJECTED = 'SEND_BROADBAND_REJECTED'
export const SEND_BROADBAND_FULFILLED = 'SEND_BROADBAND_FULFILLED'
