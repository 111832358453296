import * as types from '../action/Types';
import { setTFN, isUndefinedNullOrEmpty, setCallLogId, setAccessToken, setRefreshToken, setNbc, formatTFN, setHomeUrl } from '../../common/common';
import * as _ from 'lodash';

export const initialState = {
  accountCheck: {},
  addressIsSame: true,
  businessInfo: {},
  callLogId: '',
  canScheduleAppt: '',
  checkingAccount: false,
  chgQuestionInfo: {},
  errorMessage: '',
  cardErrorModalMsg: 'Sorry, we were unable to authorize your Credit Card. Would you like to try a different Credit Card?',
  hasError: false,
  isBusinessAddress: false,
  loading: false,
  normalizedAddress: {},
  orderInfo: {},
  orderSubmitResponse: {},
  paymentInfo: {},
  internetPlan: {},
  serviceabilityCheck: {},
  vasPlanInfo: {},
  sessionResponse: {},
  TFN: '',
  customTFN: '',
  customWhiTFN: '',
  expressLoading: true,
  expressVasProducts: [],
  vasError: false,
  buyflow: 'all',
  wirelessCartInfo: {
    wirelessPlanInfo: [],
    wirelesServeRef: ''
  },
  mobileCompatibility: {},
  checkoutInfo: {},
  isVasOnlyFlow: false,
  extendedInfo: [],
  scheduleAppt: [],
  scheduleDateTime: '',
  isElnkMailboxReq: false,
  mboxRequired: [],
  elnkDomains: [],
  isMobileContainer: false,
  cartProducts: {
    internet: {},
    mobile: []
  },
  isProductsAdded: false,
  scheduleLoading: false,
  mobileOnlyLoading: true,
  mobileProducts: [],
  mobileError: false,
  TCPADisclaimer: false,
  selectedBundleData: null,
  referrer:'',
  languagePref:null,
  mrcPosProducts:null,
  validateAddress: {},
  isRtsVisible: false,
  rtsAttemptCount: 0,
  setActiveProductTab:'',
  selectedProduct:null,
  broadbandEmailLoading: false,
  broadbandEmailError: false,
}

export default function OrderReducer(state = initialState, action) {
  let newState = { ...state }
  let item = null
  let data = null
  let res = null
  switch (action.type) {

  case types.RESET_STATE:
    newState.accountCheck = {};
    newState.addressIsSame = true;
    newState.businessInfo = {};
    newState.callLogId = '';
    newState.canScheduleAppt = '';
    newState.checkingAccount = false;
    newState.chgQuestionInfo = {};
    newState.errorMessage = '';
    newState.cardErrorModalMsg = 'Sorry, we were unable to authorize your Credit Card. Would you like to try a different Credit Card?';
    newState.hasError = false;
    newState.isBusinessAddress = false;
    newState.loading = false;
    newState.normalizedAddress = {};
    newState.orderInfo = {};
    // newState.orderSubmitResponse = {};
    newState.paymentInfo = {};
    newState.internetPlan = {};
    newState.serviceabilityCheck = {};
    newState.scheduleDateTime = '';
    // newState.vasPlanInfo = {};
    newState.checkoutInfo = {};
    // newState.wirelessCartInfo = {};
    newState.isElnkMailboxReq = false;
    newState.mboxRequired = {};
    newState.validateAddress = {};
    return newState;

  case types.RESET_SUBMIT_RESPONSE:
    newState.orderSubmitResponse = {};
    return newState;

    // AXIOS FULFILLED CALL HANDLERS

  case types.SESSION_CALL_FULFILLED:
    newState.hasError = false;
    item = isUndefinedNullOrEmpty(action.payload.data) ? null : action.payload.data;
    if (action.payload.status === 200 && item !== null) {
      newState.sessionResponse = item;
      newState.TFN = item.defaultTfn;
      setAccessToken(`Bearer ${item.accessToken}`);
      setRefreshToken(item.refreshToken);
      setTFN(formatTFN(item.defaultTfn));
      setHomeUrl(item.homeUrl);
    } else {
      newState.hasError = true;
    }
    newState.loading = false;
    return newState;

  case types.INITIAL_ORDER_CREATE_FULFILLED:
    data = isUndefinedNullOrEmpty(action.payload.data) ? null : action.payload.data
    if (action.payload.status === 201 && data !== null) {
      newState.orderInfo = data;
      newState.orderInfo.billingAddress = '';
      newState.orderInfo.email = '';
      newState.orderInfo.firstName = '';
      newState.orderInfo.lastName = '';
      newState.orderInfo.phoneNum = '';
      newState.orderInfo.callLogId = '';
      newState.orderInfo.serviceAddress = '';
    } else {
      newState.hasError = true;
    }
    newState.loading = false;
    return newState;

  case types.SUBMIT_ORDER_FULFILLED:
    data = isUndefinedNullOrEmpty(action.payload.data) ? null : action.payload.data;
    if (action.payload.status === 200 && data !== null) {
      if (data?.confirmationNo) { newState.orderSubmitResponse.confirmationNo = data.confirmationNo; }
      if (data?.customerNumber) { newState.orderSubmitResponse.customerNumber = data.customerNumber; }
      if (data?.reqInstallDate) {
        newState.orderSubmitResponse.reqInstallDate = data.reqInstallDate;
      } else {
        newState.orderSubmitResponse.reqInstallDate = null;
      }
    } else { newState.hasError = true; }
    newState.loading = false;
    return newState;

  case types.UPDATE_ORDER_FULFILLED:
    newState.hasError = false;
    newState.loading = false;
    item = isUndefinedNullOrEmpty(action.payload.data) ? null : action.payload.data;
    if (action.payload.status === 200 && item !== null) { newState.orderInfo = item; } else { newState.hasError = true; }
    newState.loading = false;
    return newState;

  case types.EXPRESS_VAS_FULFILLED:
    data = isUndefinedNullOrEmpty(action.payload.data) ? '' : action.payload.data;
    if (action.payload.status === 200 && !isUndefinedNullOrEmpty(data)) {
      newState.expressVasProducts = data;
      newState.expressLoading = false;
      newState.vasError = false;
      newState.isVasOnlyFlow = true;
    }
    return newState;

  case types.MOBILE_PRODUCTS_FULFILLED:
    data = isUndefinedNullOrEmpty(action.payload.data) ? '' : action.payload.data;
    if (action.payload.status === 200 && !isUndefinedNullOrEmpty(data)) {
      newState.serviceabilityCheck.mobileProducts = data;
      newState.mobileOnlyLoading = false;
      newState.mobileError = false;
      newState.isMobileContainer = true;
    }
    return newState;

  case types.VALIDATE_CARD_FULFILLED:
    newState.loading = false;
    return newState;

  case types.VALIDATE_ADDRESS_FULFILLED:
    res = isUndefinedNullOrEmpty(action.payload.data) ? '' : action.payload.data;
    newState.validateAddress = res;
    return newState;

  case types.CHECK_SERVICEABILITY_FULFILLED:
    data = isUndefinedNullOrEmpty(action.payload.data) ? '' : action.payload.data;
    if (action.payload.status === 200 && !isUndefinedNullOrEmpty(data)) {
      let servicePlans = [];
      let vasArray = [];

      if (data?.products) {
        const plans = data.products;
        plans.forEach((plan, index) => {
          if (plan?.downstreamSpd) {
            servicePlans[index] = plan;
          }
        });
        newState.serviceabilityCheck.plans = servicePlans;
      }

      if (data?.bundles) {
        newState.serviceabilityCheck.bundles = data.bundles
      }

      if (data?.vasProducts) {
        const vasProducts = data.vasProducts;
        vasProducts.forEach((vasPlan) => {
          vasArray.push(vasPlan);
        });
        newState.serviceabilityCheck.vasProducts = vasArray;
      }

      if (data?.generalTerms) {
        newState.generalTerms = data.generalTerms;
      }

      if (data?.normalizedAddress) {
        newState.normalizedAddress = data.normalizedAddress;
      }

      if (data?.canScheduleAppt) {
        newState.canScheduleAppt = data.canScheduleAppt;
      }

      if (data?.nbcScore) {
        newState.serviceabilityCheck.nbcScore = data.nbcScore;
        setNbc(data.nbcScore);
      }

      if (data?.suggestedAddresses) {
        let suggestedAddresses = data.suggestedAddresses;
        newState.serviceabilityCheck.suggestedAddresses = suggestedAddresses;
      }

      if (data?.callLogId) {
        const callLogId = data.callLogId;
        setCallLogId(callLogId);
        newState.callLogId = callLogId;
      }

      if (data?.serviceTerm) {
        newState.serviceabilityCheck.serviceTerm = data.serviceTerm;
      }

      if (data?.adslShippingCost) {
        newState.serviceabilityCheck.adslShippingCost = data.adslShippingCost;
      }

      if (data?.mobile) {
        newState.serviceabilityCheck.mobileCoverage = data.mobile;
      }

      if (data?.mobileProducts) {
        newState.serviceabilityCheck.mobileProducts = data.mobileProducts;
      }

      if (data?.fixedWirelessProducts) {
        newState.serviceabilityCheck.fixedWirelessProducts = data.fixedWirelessProducts;
      }

      if (data?.extendedInfo) {
        newState.extendedInfo = data.extendedInfo.serviceableService;
      }
      if (data?.mrcPosProducts) {
        newState.mrcPosProducts = data.mrcPosProducts;
      }

    } else { newState.hasError = true; }
    newState.loading = false;
    return newState;

  case types.ACCOUNT_CHECK_FULFILLED:
    data = isUndefinedNullOrEmpty(action.payload.data) ? null : action.payload.data
    if (action.payload.status === 200 && data !== null) {
      if (data?.suggestedIsValid) {
        newState.accountCheck.emailIsAvailable = true
        newState.checkingAccount = false
      } else {
        if (data?.suggestedId) {
          const { suggestedId } = data;
          const suggestions = []
          suggestedId.map(suggestion => (
            suggestions.push(suggestion.userId)
          ))
          newState.accountCheck.emailIsAvailable = false;
          newState.checkingAccount = false
          newState.accountCheck.suggestedIds = suggestions;
        } else {
          newState.hasError = true;
          newState.checkingAccount = false
          newState.accountCheck.emailIsAvailable = false;
        }
      }
    } else {
      newState.hasError = true;
      newState.checkingAccount = false
      newState.accountCheck.emailIsAvailable = false;
    }
    return newState;

  case types.SERVICE_CHECK_FULFILLED:
    newState.serviceabilityCheck.serviceCheckId = action.payload
    newState.loading = false;
    if (action.payload && (action.payload.status !== 201)) { newState.hasError = true; }
    return newState;

  case types.CHECK_MOBILE_PRODUCTS_FULFILLED:
    data = isUndefinedNullOrEmpty(action.payload.data) ? '' : action.payload.data;
    if (action.payload.status === 200 && !isUndefinedNullOrEmpty(data)) {
      let servicePlans = [];
      servicePlans = data
      newState.serviceabilityCheck.mobileProducts = servicePlans;
    } else { newState.hasError = true; }
    newState.loading = false;
    return newState;

  case types.CHECK_DEVICE_COMPATIBILITY_FULFILLED:
    data = isUndefinedNullOrEmpty(action.payload.data) ? '' : action.payload.data;
    if (data?.deviceCompatibility) {
      newState.mobileCompatibility.deviceCompatibility = data.deviceCompatibility;
    }
    newState.loading = false;
    return newState;

  case types.SCHEDULE_APPT_CHECK_FULFILLED:
    data = isUndefinedNullOrEmpty(action.payload.data) ? '' : action.payload.data;
    if (data?.installWindow && data.installWindow.length) {
      newState.scheduleAppt = data.installWindow;
    } else {
      newState.scheduleAppt = [];
    }
    newState.scheduleLoading = false;
    return newState;

  case types.GET_ACC_DOMAINS_FULFILLED:
    data = isUndefinedNullOrEmpty(action.payload.data) ? '' : action.payload.data;
    if (data?.items) {
      newState.elnkDomains = data.items;
    }
    return newState;

  case types.UPDATE_TOKENS:
    if (action.tokens && action.tokens.refreshToken && action.tokens.accessToken) {
      let { accessToken, refreshToken } = action.tokens;
      newState.sessionResponse.accessToken = accessToken;
      newState.sessionResponse.refreshToken = refreshToken;
      setAccessToken(`Bearer ${accessToken}`);
      setRefreshToken(refreshToken);
    }
    return newState;

  case types.GET_BUNDLES_DETAILS_FULFILLED: {
    const requiredProd = ['plans', 'mobileProducts', 'fixedWirelessProducts', 'vasProducts'];
    const allProducts = requiredProd.reduce((acc, curVal) => [...acc, ...newState.serviceabilityCheck[curVal]], []);
    let bundlesProdWithServRefs = action?.payload?.data?.bundles?.map((bundleProduct) => {
      const product = [];
      for (const i of [1, 2, 3, 4]) {
        if (
          bundleProduct[`servLevel${i}`] === '*' &&
            bundleProduct[`servCode${i}`]?.toString().toLowerCase() === 'ipbb'
        ) {
          product.push('ipbb*');
        }
        if (bundleProduct[`servLevel${i}`] === '*' &&
            bundleProduct[`servCode${i}`]?.toString()?.toLowerCase() === 'mobile') {
          product.push('mobile*');
        }
        const filterProduct = allProducts.find((e) => {
          return (
            e.servCode?.toLowerCase() === bundleProduct[`servCode${i}`]?.toString().toLowerCase() &&
              e.servLevel?.toLowerCase() === bundleProduct[`servLevel${i}`]?.toString().toLowerCase()
          );
        });
        if (filterProduct) {
          product.push(filterProduct?.servRef);
        }
      }
      bundleProduct.productsServRef = product;
      return bundleProduct;
    });
    newState.serviceabilityCheck.bundlesDetails = bundlesProdWithServRefs;
    newState.error = false;
    newState.loading = false;
    return newState;
  }

  case types.SET_BUNDLE_PRODUCT:
    newState.selectedBundleData = action.payload;
    return newState;

  case types.SEND_BROADBAND_FULFILLED:
    newState.broadbandEmailLoading = false;
    newState.broadbandEmailError = false;
    return newState;

  case types.SEND_BROADBAND_PENDING:
    newState.broadbandEmailLoading = true;
    newState.broadbandEmailError = false;
    return newState;

  case types.SEND_BROADBAND_REJECTED:
    newState.broadbandEmailLoading = false;
    newState.broadbandEmailError = true;
    return newState;

    // UPDATE STORE CALLS
  case types.SAVE_STEP_ONE:
    newState.isProductsAdded = action.payload
    return newState;

  case types.SAVE_CHALLENGE_QUESTION_INFO:
    newState.chgQuestionInfo = action.payload
    return newState;

  case types.SET_CUSTOM_TFN: {
    newState.customTFN = action.payload;
    return newState;
  }

  case types.SET_CUSTOM_WHI_TFN: {
    newState.customWhiTFN = action.payload;
    return newState;
  }

  case types.SET_REFERRER: {
    newState.referrer = action.payload
    return newState;
  }

  case types.SET_ACTIVE_PRODUCT_TAB:
    newState.setActiveProductTab = action.payload;
    return newState;
  case types.SET_SELECTED_PRODUCT:
    newState.selectedProduct = action.payload;
    return newState;

  case types.SET_TFN: {
    let nbc = action.payload;

    if (!isNaN(nbc) && nbc.length === 10) {
      newState.TFN = nbc;
      return newState;
    }

    const { aTfn, bTfn, cTfn, dTfn, eTfn, abaTfn, defaultTfn } = state.sessionResponse;
    if (_.isEqual(nbc, 'A')) {
      newState.TFN = aTfn;
    } else if (_.isEqual(nbc, 'B')) {
      newState.TFN = bTfn;
    } else if (_.isEqual(nbc, 'C')) {
      newState.TFN = cTfn;
    } else if (_.isEqual(nbc, 'D')) {
      newState.TFN = dTfn;
    } else if (_.isEqual(nbc, 'E')) {
      newState.TFN = eTfn;
    } else if (_.isEqual(nbc, 'ABNDCART')) {
      newState.TFN = abaTfn;
    } else if (_.isEqual(nbc, 'thanks')) {
      newState.TFN = '8883278454';
    } else {
      newState.TFN = defaultTfn;
    }
    return newState;
  }

  case types.UPDATE_IS_BUSINESS_ADDRESS:
    newState.isBusinessAddress = action.payload;
    return newState;

  case types.SAVE_BUSINESS_INFO:
    newState.businessInfo = action.payload;
    return newState;

  case types.SAVE_PAYMENT_INFO:
    newState.paymentInfo = action.payload
    return newState;

  case types.SAVE_BUYFLOW:
    newState.buyflow = action.payload
    return newState;

  case types.SAVE_CHECKOUT_INFO:
    newState.checkoutInfo = action.payload
    return newState;

  case types.SET_ELNK_MAILBOX_REQ:
    newState.isElnkMailboxReq = action.payload;
    return newState;

  case types.SET_MAILBOX_REQ:
    if (Object.entries(action.payload).find(key => key[1] === true) !== undefined) {
      newState.isElnkMailboxReq = true;
    } else {
      newState.isElnkMailboxReq = false;
    }
    newState.mboxRequired = action.payload;
    return newState;

  case types.SET_EXPRESS_LOADING:
    newState.expressLoading = action.payload;
    return newState;

  case types.UPDATE_CUST_TC:
    newState.paymentInfo.customerAcceptedTC = action.payload
    return newState;

  case types.UPDATE_ERROR_MSG:
    newState.errorMessage = action.payload
    return newState;

  case types.UPDATE_CARD_ERROR:
    newState.cardErrorModalMsg = action.payload
    return newState;

  case types.SAVE_PLAN_INFO:
    newState.internetPlan = action.payload
    return newState;

  case types.SAVE_VAS_PLAN_INFO:
    newState.vasPlanInfo = action.payload
    return newState;

  case types.SAVE_WIRELESS_PLAN_INFO:
    newState.wirelessCartInfo.wirelessPlanInfo = action.payload.wirelessPlanInfo;
    newState.wirelessCartInfo.wirelesServeRef = action.payload.wirelesServeRef;
    return newState;

  case types.SAVE_PASSWORD:
    newState.accountCheck.password = action.payload;
    return newState;

  case types.SAVE_ADDRESS_IS_SAME:
    newState.addressIsSame = action.payload;
    return newState;
  case types.SAVE_SCHEDULE_DATE_TIME:
    newState.scheduleDateTime = action.payload;
    return newState;
  case types.SAVE_TCPA_DISCLAIMER:
    newState.TCPADisclaimer = action.payload;
    return newState;
  case types.SAVE_SPANISH_LANGUAGE_PREFERENCE:
    newState.languagePref = action.payload;
    return newState;
  case types.SET_MOBILE_CONTAINER:
    newState.isMobileContainer = action.payload;
    return newState;
    // PENDING API CALL HANDLERS
  case types.EXPRESS_VAS_PENDING:
    newState.expressLoading = true;
    newState.vasError = false;
    return newState;

  case types.MOBILE_PRODUCTS_PENDING:
    newState.mobileOnlyLoading = true;
    newState.mobileError = false;
    return newState;

  case types.SCHEDULE_APPT_CHECK_PENDING:
    newState.scheduleLoading = true;
    return newState;
  case types.SESSION_CALL_PENDING:
  case types.INITIAL_ORDER_CREATE_PENDING:
  case types.CHECK_SERVICEABILITY_PENDING:
  case types.SERVICE_CHECK_PENDING:
  case types.UPDATE_ORDER_PENDING:
  case types.SUBMIT_ORDER_PENDING:
  case types.VALIDATE_CARD_PENDING:
  case types.CHECK_MOBILE_PRODUCTS_PENDING:
  case types.CHECK_DEVICE_COMPATIBILITY_PENDING:
    newState.hasError = false;
    newState.errorMessage = '';
    newState.loading = true;
    return newState;
  case types.ACCOUNT_CHECK_PENDING:
    newState.checkingAccount = true;
    return newState;

  case types.GET_BUNDLES_DETAILS_PENDING:
    newState.loading = true;
    return newState;
    // REJECTED API CALL HANDLERS

    // case types.INITIAL_ORDER_CREATE_REJECTED:
  case types.ACCOUNT_CHECK_REJECTED:
    newState.checkingAccount = false;
    return newState;
  case types.EXPRESS_VAS_REJECTED:
    newState.expressLoading = false;
    newState.vasError = true;
    return newState;

  case types.MOBILE_PRODUCTS_REJECTED:
    newState.mobileOnlyLoading = false;
    newState.mobileError = true;
    return newState;

  case types.SCHEDULE_APPT_CHECK_REJECTED:
    newState.scheduleLoading = false;
    return newState;
  case types.SESSION_CALL_REJECTED:
  case types.SERVICE_CHECK_REJECTED:
  case types.CHECK_SERVICEABILITY_REJECTED:
  case types.UPDATE_ORDER_REJECTED:
  case types.SUBMIT_ORDER_REJECTED:
  case types.VALIDATE_CARD_REJECTED:
  case types.CHECK_MOBILE_PRODUCTS_REJECTED:
  case types.CHECK_DEVICE_COMPATIBILITY_REJECTED:
    newState.loading = false;
    newState.hasError = true;
    return newState;
  case types.GET_BUNDLES_DETAILS_REJECTED:
    newState.error = true;
    newState.loading = false;
    return newState;
  case types.SET_RTS_VISIBLE:
    newState.isRtsVisible = action.payload;
    return newState;
  case types.SET_RTS_ATTEMPT_COUNT:
    newState.rtsAttemptCount = action.payload;
    return newState;
  default: { return state }
  }
}
