
const appConfig = window.AppConfig || {};

let initialized = false;
let configs;


/**
 * Set and valid configs from process.env.
 *
 * @throws {Error} Will throw error if validation fails.
 */
export const initConfigs = () => {
  configs = {
    API_ENDPOINT: appConfig.REACT_APP_API_ENDPOINT || process.env.REACT_APP_API_ENDPOINT || '',
    CHECK_SERVICEABILITY: appConfig.REACT_APP_CHECK_SERVICEABILITY ||  process.env.REACT_APP_CHECK_SERVICEABILITY || '',
    CHASEPAYMENT_URL_GETKEY: appConfig.REACT_APP_CHASEPAYMENT_URL_GETKEY || process.env.REACT_APP_CHASEPAYMENT_URL_GETKEY ||  '',
    CHASEPAYMENT_URL_ENCRYPTION: appConfig.REACT_APP_CHASEPAYMENT_URL_ENCRYPTION  ||  process.env.REACT_APP_CHASEPAYMENT_URL_ENCRYPTION ||'',
    APP_VERSION: appConfig.REACT_APP_APP_VERSION || process.env.REACT_APP_APP_VERSION || '1.0',
    BUSINESS_PARTNER: appConfig.REACT_APP_BUSINESS_PARTNER || process.env.REACT_APP_BUSINESS_PARTNER ||'elnkwww',
    RESIDENTIAL_PARTNER: appConfig.REACT_APP_RESIDENTIAL_PARTNER || process.env.REACT_APP_RESIDENTIAL_PARTNER || 'elnkafil',
    SMARTY_API_KEY: appConfig.REACT_APP_SMARTY_API_KEY  ||  process.env.REACT_APP_SMARTY_API_KEY || '',
    SECURITY_TOOLS_URL: appConfig.REACT_APP_SECURITY_TOOLS_URL  ||  process.env.REACT_APP_SECURITY_TOOLS_URL || 'https://www.earthlink.net/security-and-tools',
    RESULTS_URL: appConfig.REACT_APP_RESULTS_URL  ||  process.env.REACT_APP_RESULTS_URL || 'http://results.earthlink.com/',
    EASY_TECH_URL: appConfig.REACT_APP_EASY_TECH_URL || process.env.REACT_APP_EASY_TECH_URL || 'https://dev1-earthlink.serverguy.com/easytech/',
    SHOW_SCHEDULE_APPT: appConfig.REACT_APP_SHOW_SCHEDULE_APPT || process.env.REACT_APP_SHOW_SCHEDULE_APPT || false,
    MAZAMA_WEBHOOK_URL: appConfig.REACT_APP_MAZAMA_WEBHOOK_URL || process.env.REACT_APP_MAZAMA_WEBHOOK_URL || false,
    SHOW_PSWD_CQAS: appConfig.REACT_APP_SHOW_PSWD_CQAS || process.env.REACT_APP_SHOW_PSWD_CQAS || false,
    CALL_CENTER_TIME: appConfig.REACT_APP_CALL_CENTER_TIME || process.env.REACT_APP_CALL_CENTER_TIME || false,
    SHOW_CALL_NOW: appConfig.REACT_APP_SHOW_CALL_NOW || process.env.REACT_APP_SHOW_CALL_NOW || false,
    BASE_URL: appConfig.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL || 'https://results.earthlink.com/',
    SHOW_MOBILE_OPTIONS: appConfig.REACT_APP_SHOW_MOBILE_OPTIONS || process.env.REACT_APP_SHOW_MOBILE_OPTIONS || 'false',
    CONSUMER_AUTH: appConfig.REACT_APP_CONSUMER_AUTH || process.env.REACT_APP_CONSUMER_AUTH || '',
    SHOW_BUNDLES: appConfig.REACT_APP_SHOW_BUNDLES || process.env.REACT_APP_SHOW_BUNDLES || 'false',
    SHOW_TERM_CONTRACT: appConfig.REACT_APP_SHOW_TERM_CONTRACT || process.env.REACT_APP_SHOW_TERM_CONTRACT || 'false',
    DNI_API_SCRIPT_SRC: appConfig.REACT_APP_DNI_API_SCRIPT_SRC || process.env.REACT_APP_DNI_API_SCRIPT_SRC || 'false',
    NEXT_BASE_URL: appConfig.REACT_APP_NEXT_BASE_URL || process.env.REACT_APP_NEXT_BASE_URL || 'false',
  };

  if (!/^http(s)?:/i.test(configs.API_ENDPOINT)) {
    throw new Error('Expected REACT_APP_API_ENDPOINT to be valid uri');
  }
  if (!/^http(s)?:/i.test(configs.CHECK_SERVICEABILITY)) {
    throw new Error('Expected REACT_APP_CHECK_SERVICEABILITY to be valid uri');
  }
  if (!/^http(s)?:/i.test(configs.CHASEPAYMENT_URL_GETKEY)) {
    throw new Error('Expected REACT_APP_CHASEPAYMENT_URL_GETKEY to be valid uri');
  }
  if (!/^http(s)?:/i.test(configs.CONSUMER_AUTH)) {
    throw new Error('Expected REACT_APP_CONSUMER_AUTH to be valid uri');
  }
  if (!/^http(s)?:/i.test(configs.CHASEPAYMENT_URL_ENCRYPTION)) {
    throw new Error('Expected REACT_APP_CHASEPAYMENT_URL_ENCRYPTION to be valid uri');
  }
  if (!configs.SMARTY_API_KEY || configs.SMARTY_API_KEY === '') {
    throw new Error('REACT_APP_SMARTY_API_KEY is required');
  }

  initialized = true;
};

/**
 * Get configs.
 *
 * @throws {Error} Will throw error if configs have not been initialized.
 */
export const getConfigs = () => {
  if (!initialized) {
    initConfigs();
  }

  return configs;
};
