import React from 'react';
import { getValid$Cost } from '../../common/common';
import { useCartData } from '../../hooks/order';

const MobileInfomration = () => {
  const { simCharge = 0 } = useCartData();

  return (
    <>
      <div className="cart-info-panel common-pannel mobile-info">
        <div className="b-bg-color">
          <h3>Mobile Information</h3>
        </div>
        <ul>
          <li>
            SIM card fee collected today <b>({getValid$Cost(simCharge?.toFixed(2))}).</b>{' '}
            Card(s) shipped and arrive within <b>1 to 3 days Business Days (M-F).</b>
          </li>
          <li>
            Orders submitted before <b>6pm ET (M-F)</b> are shipped same day,
            otherwise next business day.
          </li>
          <li>
            Prices quoted <b>do not</b> include applicable sales and telecom
            taxes.
          </li>
        </ul>
      </div>
    </>
  );
}
export default MobileInfomration;
