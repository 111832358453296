import React from 'react';
import { SECURITY_TOOLS_URL } from '../../common/common';
import { Row, Col } from 'antd';
import { vasLearnMoreLinks } from '../../util/util'

export const Guardian = () => {
  const { guardian } = vasLearnMoreLinks;
  return (
    <div className="protect-section border-same m-top-3 same-protect">
      <Row>
        <Col span={16}>
          <div className="protect-title">
            <h2>EarthLink Guardian</h2>
            <h4>Helping families manage and protect their children’s online lives</h4>
            <p>EarthLink Guardian, powered by Bark, is an award-winning monitoring and screen time management tool that keeps kids safer online while preserving their privacy.</p>
          </div>
        </Col>
        <Col span={8} className="text-center responsive-m-t-2">
          <div className="protect-contact">
            {/* <a href="#" className="buy-now-btn"><i className="fa fa-shopping-cart"></i>Buy Now</a> */}
            <a href="tel:833-601-5660" className="blue-button"><i className="fa fa-phone"></i>Call 833-601-5660</a>
            <h4>
              $9.95/mo.
              <br></br>
              Free 30-day trial*
            </h4>
          </div>
        </Col>
      </Row>

      <div className="what-you-get">
        <h3>What you Get</h3>
      </div>

      <Row>
        <Col span={12}>
          <ul className="blue-circle-lists">
            <li><i className="fas fa-check-circle"></i> Monitoring of texts, email, YouTube, and 30+ apps and platforms (extensive list of what EarthLink Guardian monitors
              <a href={SECURITY_TOOLS_URL + 'parental-control/what-earthlink-guardian-monitors/'}>&nbsp;here</a>
              )</li>
            <li><i className="fas fa-check-circle"></i> Receive alerts for issues like cyberbullying, online predators, suicidal ideation, sexual content, and more</li>
            <li> <i className="fas fa-check-circle"></i>Manage screen time</li>
          </ul>
        </Col>
        <Col span={12}>
          <ul className="blue-circle-lists protect-contact">
            <li><i className="fas fa-check-circle"></i>Filter which websites your kids can visit</li>
            <li><i className="fas fa-check-circle"></i>Keep up with your kids with location check-ins</li>
            <li><i className="fas fa-check-circle"></i>Free 30-day trial</li>
            <a href={SECURITY_TOOLS_URL + guardian} className="blue-button m-top-1">About EarthLink Guardian</a>
          </ul>
        </Col>
      </Row>
    </div>
  )
}

export default Guardian;
