export default function useDNISDK() {
  const {
    isEnabled = false,
    phone,
    visitId: dniId = '',
  } = global?.window?._elnkDNISDK?.getSession() || {};
  return {
    dniId,
    isEnabled,
    phoneNumber: (phone?.number || '').replace('+1', ''),
    sdk: global?.window?._elnkDNISDK || {},
  };
}
