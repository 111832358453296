import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getProductPrice, getValid$Cost, replaceServiceName } from '../../common/common';
import { useCartData, useOrderSubmissionData, useServiceablitityData, useSessionData, useVasData } from '../../hooks/order';
import {routesConfig, WHI_TFN } from '../../util/util';
import StaticVas from '../common/StaticVas';
import { resetState, setTFN } from '../../store/action/OrderAction';

const ThankYou = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orderSubmitResponse } = useOrderSubmissionData();
  const { confirmationNo, customerNumber } = orderSubmitResponse;
  const { isMobileAdded, isInternetAdded, modemFee, internetPlan, wirelessCartInfo, simCharge, monthlyCharge, vasPlan, serviceTerm, getOneTimeChargesLabel, getOneTimeChargesValue, getOneTimeCost, mrcPosMobileAmount, isBundleAdded, bundleDiscount, selectedBundleData, getMobileMRCPrce, getMobilePriceLabel, isWhiAdded , translateLanguage } = useCartData();
  const { orderInfo } = useSessionData();
  const { isVasOnlyFlow } = useVasData();
  const { billingAddress, accountEmail, firstName, email, phoneNum } = orderInfo;
  const { serviceabilityCheck } = useServiceablitityData();
  const { plans = [], mobileProducts = [], fixedWirelessProducts = [] } = serviceabilityCheck;
  const { wirelessPlanInfo = [] } = wirelessCartInfo;
  const isVasAdded = vasPlan && vasPlan.length > 0

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!plans.length && !mobileProducts.length && fixedWirelessProducts.length) {
      dispatch(setTFN(WHI_TFN));
    } else {
      dispatch(setTFN('thanks'));
    }
    if (isEmpty(orderSubmitResponse)) {
      navigate(routesConfig.home)
    } else {
      dispatch(resetState);
    }
  }, []);
  let UserTFN = phoneNum?.slice('-4');

  const thanksMessage = () => {
    if (isInternetAdded) {
      return (
        <>
          <p className="thanks-main-description">{translateLanguage.thankuAndWelcome}!</p>
          <p style={{ marginTop: '10px' }}>{translateLanguage.text1}: </p>
          <p>1) {translateLanguage.text12} <span>{UserTFN}</span>) {translateLanguage.text13} 2) {translateLanguage.welcomeEmail}</p>
          <p style={{ marginTop: '10px' }}>{translateLanguage.text14} <a href="https://www.earthlink.net/live-chat/"> {translateLanguage.text15}</a> {translateLanguage.text16} <a href="tel:888-327-8454">888-327-8454</a>.</p>
        </>
      )

    }
    return (
      <>
        <p>
          {translateLanguage.thanksMsg} <a href="tel:888-327-8454">888-327-8454</a>.
        </p>
      </>
    )
  }

  return (
    <>
      <div className="elink-container p-top-5 height-big-screen">
        <div className="result-heading text-center">
          <h1>{translateLanguage.thanku}, {firstName}!</h1>
        </div>
        <div className="outer-thanks">
          <div className="main-thanks">
            <div className="order-daetil-sec">
              <h4>{translateLanguage.orderDetails}</h4>
              {thanksMessage()}

            </div>
            <div className="account-detail">
              <div className="f-detail grid-col-auto">
                <div>
                  <h5>{translateLanguage.confirmationNumber}</h5>
                  <p>{confirmationNo}</p>
                </div>
                <div>
                  <h5>{translateLanguage.accountNumber}</h5>
                  <p>{customerNumber}</p>
                </div>
                <div>
                  <h5>{translateLanguage.emailAddress}</h5>
                  <p>{accountEmail || email}</p>
                </div>
                <div className="s-detail">
                  {billingAddress && <div>
                    <h5>{translateLanguage.billingAddress}</h5>
                    <p>{billingAddress}</p>
                  </div>}
                </div>
              </div>
            </div>
            <div className="charges-detail grid-col-auto">
              {!isVasOnlyFlow && <div className="b-bg-color charges_dv">
                <h5>{translateLanguage.oneTimeCharges}</h5>
                <ul>
                  {isInternetAdded && <li className="d-flex j-between p-relative">
                    <div className="text-captilize">{getOneTimeChargesLabel()}</div>
                    <div><b>{getOneTimeCost()}</b></div>
                    <div className="dotted"></div>
                  </li>}
                  {isMobileAdded &&
                  <>
                    {
                      mrcPosMobileAmount > 0 &&
                      wirelessPlanInfo?.map((mobilePlan) => (
                        <li key={mobilePlan.servRef} className="d-flex j-between p-relative">
                          <div>{ mrcPosMobileAmount > 0  && getMobilePriceLabel(mobilePlan)}</div>
                          <div><b>{getValid$Cost(getMobileMRCPrce(mobilePlan))}</b></div>
                          <div className="dotted"></div>
                        </li>
                      ))
                    }
                    <li className="d-flex j-between p-relative">
                      <div>{translateLanguage.SIMCardFee}</div>
                      <div><b>{getValid$Cost(simCharge?.toFixed(2))}</b></div>
                      <div className="dotted"></div>
                    </li>
                  </>
                  }
                </ul>
              </div>}
              <div className="b-bg-color charges_dv">
                <h5>{translateLanguage.monthlyCharges}</h5>
                <ul>
                  {isInternetAdded && <>
                    <li className="d-flex j-between p-relative">
                      <div>{translateLanguage.modemLease}</div>
                      <div><b> {getValid$Cost(modemFee)}</b></div>
                      <div className="dotted"></div>
                    </li>
                    <li className="d-flex j-between p-relative">
                      <div className="text-captilize">{replaceServiceName(internetPlan.serviceName)}</div>
                      <div><b>{getProductPrice(internetPlan, serviceTerm)}</b></div>
                      <div className="dotted"></div>
                    </li>
                  </>
                  }
                  {isMobileAdded &&
                    wirelessPlanInfo && wirelessPlanInfo.map((mobilePlan, i) => (
                    <li className="d-flex j-between p-relative" key={i}>
                      <div>({mobilePlan.quantity}) {translateLanguage.mobile} {''} {mobilePlan.serviceName.replace(' Plan', '')}</div>
                      <div><b>{getValid$Cost(getMobileMRCPrce(mobilePlan))}/mo</b></div>
                      <div className="dotted"></div>
                    </li>
                  ))
                  }
                  {isVasAdded &&
                    vasPlan.map((plan, i) => (
                      <li className="d-flex j-between p-relative" key={i}>
                        <div>{plan.serviceName}</div>
                        <div><b>{getValid$Cost(plan.price)}</b></div>
                        <div className="dotted"></div>
                      </li>
                    ))
                  }
                  {isBundleAdded &&
                    <li className="d-flex j-between p-relative">
                      <div style={{ flexDirection: 'column' }}>
                        <div className="bundle-discount">{translateLanguage.bundleDiscount}</div>
                        <span>({selectedBundleData?.bundleName})</span>
                      </div>
                      <div><b>${(bundleDiscount).toFixed(2)}</b></div>
                      <div className="dotted"></div>
                    </li>
                  }
                </ul>
              </div>

            </div>
            <div className="total-billing d-flex j-between">
              <div>
                <h5>Total</h5>
                <p>{translateLanguage.plusApplicableTaxes}</p>
              </div>
              <div className="d-flex j-between">
                {!isVasOnlyFlow && <div>
                  <h5>{getOneTimeChargesValue()}</h5>
                  <p>{translateLanguage.today}</p>
                </div>}
                <div className={`${!isVasOnlyFlow ? 'line-after' : ''}`}>
                  <h5>{getValid$Cost(isBundleAdded ? (Number(monthlyCharge) - bundleDiscount).toFixed(2) : monthlyCharge)}</h5>

                  <p>{translateLanguage.monthly}</p>
                </div>
              </div>
            </div>
          </div>
          {isMobileAdded &&(mrcPosMobileAmount > 0 ? '' : <div className="mobile-info">*{translateLanguage.Mobilenotcharged}</div>)}
        </div>
        {!isVasAdded && !isWhiAdded && <div><StaticVas /></div>}
      </div>
    </>
  )
}
export default ThankYou;
