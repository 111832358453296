import React, { useCallback, useEffect, useState } from 'react';
import { formatTFN, getHomeUrl, getTFN } from '../../common/common';
import { EECCheckout, pageLoad } from '../../common/GTMScripts';
import useDNISDK from '../../hooks/dni';
import { useCallCenterFlag, useCartData, useLoading, useServiceablitityData, useSessionData } from '../../hooks/order';
import { cartLogo, earthlinkLogo } from '../../images/index';
import { routesConfig } from '../../util/util';
import FixedCardFooter from './FixedCardFooter';
import { saveSpanishLanguagePreference } from '../../store/action/OrderAction';
import { Switch } from 'antd';
import { useDispatch } from 'react-redux';

const HeaderMain = () => {
  const dispatch = useDispatch();
  const { productCount, languagePref } = useCartData();
  const { sessionResponse, TFN, customTFN, customWhiTFN } = useSessionData();
  const { isInternetAdded, internetPlan, wirelessCartInfo, vasPlan } = useCartData();
  const [showCart, setShowCart] = useState(true);
  const [tfn, setTfn] = useState(TFN ? formatTFN(TFN) : getTFN());
  const { loading, scheduleLoading } = useLoading();
  const pathname = '/' + window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
  const showFooter = pathname.includes(routesConfig.products) || pathname.includes(routesConfig.vasPage) || pathname.includes(routesConfig.checkout) || pathname.includes('servRef');
  const childRef = React.createRef();
  const { showCallNow, showPromoStickers } = useCallCenterFlag();
  const { homeUrl = '', agentId = '' } = sessionResponse;
  const { serviceabilityCheck } = useServiceablitityData();
  const { fixedWirelessProducts = [] , suggestedAddresses } = serviceabilityCheck;
  const { isEnabled: isDNISDKEnabled, phoneNumber: dniPhoneNumber } = useDNISDK();

  useEffect(() => {
    if (loading) {
      document.body.classList.add('body-loading');
    } else {
      document.body.classList.remove('body-loading');
    }
  }, [loading]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const HLPlan = isInternetAdded ? [internetPlan] : [];
    const allProducts = [...HLPlan, ...wirelessCartInfo.wirelessPlanInfo, ...vasPlan];
    if (sessionResponse && !window.location.href.includes('addr')) {
      pageLoad(sessionResponse.uid)
    }
    if (productCount > 0) {
      EECCheckout(allProducts)
    }
    if (window.location.href.includes(routesConfig.thanks) || window.location.href.includes(routesConfig.reviewOrder)) {
      setShowCart(false)
    } else {
      setShowCart(true)
    }
    if (window.location.href.includes(routesConfig.products)) {
      document.body.classList.add('product-page');
    } else {
      document.body.classList.remove('product-page');
    }
  }, [sessionResponse, window.location.href]);

  useEffect(() => {
    if (TFN) {
      setTfn(formatTFN(TFN));
    }
  }, [TFN, window.location.href])

  const handleCartClick = () => {
    childRef.current.cartClick();
    document.body.classList.add('cart-clicked');
  }


  const customheadertfn = () => {
    if (isDNISDKEnabled && dniPhoneNumber !== '') {
      return formatTFN(dniPhoneNumber)
    }else if (homeUrl.includes('inmycity') && customTFN === '') {
      if (suggestedAddresses?.length > 0 ){
        return '844-660-1486'
      }else if(fixedWirelessProducts?.length > 0){
        return '866-618-0480'
      }
      return '866-906-9434'
    } else if (fixedWirelessProducts?.length > 0 && customWhiTFN) {
      return customWhiTFN
    } else if ((homeUrl.includes('inmycity') || homeUrl.includes('members') || homeUrl.includes('top10')) || (homeUrl.includes('giftcard') || (agentId === 'lp_elnkwww_giftcard' && homeUrl.includes('earthlinkinternet')) || (agentId === 'lp_elnk_home' && homeUrl.includes('home')) || (agentId === 'lp_elnk_earthlinkinternet')) && customTFN !== '') {
      return customTFN
    } else {
      return tfn
    }
  }

  const onChange = useCallback(() => {
    dispatch(saveSpanishLanguagePreference(!languagePref))
  }, [languagePref]);

  return (
    <>
      {showFooter && !scheduleLoading && Object.keys(sessionResponse).length > 0 &&
        <div>
          <FixedCardFooter ref={childRef} />
        </div>
      }
      <div className={`outer-header ${(showCallNow || showPromoStickers) ? 'agent_online_header' : ''}`}>
        <header>
          <div className="elink-container">
            <div className="d-flex j-between al-center">
              <div className="logo">
                <a href={getHomeUrl()}>
                  <img src={earthlinkLogo} />
                </a>
              </div>
              <div className="toggle-btn">
                <div className="btn-with-toogle">
                  {pathname === '/thanks' && (
                    <Switch
                      onChange={onChange} checkedChildren="Spanish" unCheckedChildren="English" checked={languagePref} />
                  )}
                </div>
              </div>
              {/* {(showCallNow || showPromoStickers) && <div className="h-gift-card">
                <i className="fas fa-credit-card"></i> Get a Free $100 Gift Card!
              </div>} */}
              <div className="d-flex al-center">
                <div className="header-tfn d-flex al-center">
                  <a className={`${!customTFN && !customWhiTFN && 'elnk-dni-link'}`} href={`tel:${customheadertfn()}`} data-ctc="header">
                    <i className="fa-solid fa-mobile-screen-button"></i>
                    <span className={`phone-span ${!customTFN && !customWhiTFN && 'elnk-dni'}`}>{customheadertfn()}</span>
                  </a>
                </div>
                {showCart && <div className="header-cart d-flex al-center" onClick={() => { handleCartClick() }} onKeyDown={()=>{}}>
                  <img src = {cartLogo} />
                  <span>{productCount}</span>
                </div>}
              </div>

            </div>

          </div>

        </header>
      </div>
    </>
  )
}

export default HeaderMain;
