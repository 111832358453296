import React, { useEffect } from 'react';
import { useCallCenterFlag, useLoading } from '../../hooks/order';
import { routesConfig } from '../../util/util';
const Footer = () => {
  const { loading, scheduleLoading } = useLoading();
  const { showCallNow } = useCallCenterFlag();
  const pathname = '/' + window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
  const showFooter = pathname.includes(routesConfig.products) || pathname.includes(routesConfig.vasPage) || pathname.includes(routesConfig.checkout) || pathname.includes('servRef');
  useEffect(() => {
    if (loading) {
      document.body.classList.add('body-loading');
    } else {
      document.body.classList.remove('body-loading');
    }
  }, [loading])
  return (
    <>

      <footer className={`${loading || scheduleLoading ? 'without-fixed-footer' : ''}`} style={showFooter && !showCallNow ? { marginTop: '0px', marginBottom: '3.8rem' } : {}}>
        <div className="elink-container">
          <div className="d-flex j-between al-center">
            <div>&copy; {new Date().getFullYear()} EarthLink, LLC · All rights reserved</div>
            <div>
              <a href="https://www.earthlink.net/tcs/privacy-policy/" title="Privacy Policy">Privacy Policy</a> · <a href="https://www.earthlink.net/tcs/interest-based-ads/">Interest-Based Ads</a>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer;
