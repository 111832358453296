import React, { useState } from 'react';
import { Form, Input, Row, Col } from 'antd';
import { checkDeviceCompatibility } from '../../../store/action/OrderAction';
import { useDispatch } from 'react-redux';
import { isUndefinedNullOrEmpty } from '../../../common/common';
import { Loader } from '../../common/Loader';
import { useSessionData } from '../../../hooks/order';

const CompatibilityCheck = (props) => {
  const dispatch = useDispatch();
  const { sessionResponse } = useSessionData();

  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState('');
  const [form] = Form.useForm();

  const handleSubmit = (value) => {
    const affiliateId = sessionResponse?.partnerId || '';
    let deviceBody = {
      partner: 'elnkac',
      mobileImei: value?.imei,
      affiliateId,
    };
    try {
      setLoading(true);
      dispatch(checkDeviceCompatibility(deviceBody)).then(response => {
        if (response.value.data.deviceCompatibility === 'COMPATIBLE') {
          setMsg('Compatible')
          setLoading(false);
        } else {
          setMsg('Not Compatible')
          setLoading(false);
        }
      }).catch(() => {
        setMsg('Not Compatible')
        setLoading(false);

      })
    } catch (err) {
      if (!isUndefinedNullOrEmpty(err)) {
        setMsg('Not Compatible')
        setLoading(false);
      }
    }
  }

  const resultColor = () => {
    if (loading) {
      return ''
    } else {
      if (msg === 'Compatible') {
        return 'text-compatible'
      } else {
        return 'not-compatible'
      }
    }

  }

  const onChangehandler=(e)=>{
    const value = e?.target?.value;
    if (!isUndefinedNullOrEmpty(value)) {
      const onlyNumber = value?.replace(/[^\d]/g, '')?.replace(' ', '')?.substring(0,17);
      form.setFieldsValue({ imei: onlyNumber });
    }
    if (msg) { setMsg('') }
  }

  return (
    <>
      <div className="compatible-tab-checkout same-common">
        <p>Enter the IMEI number for a device you plan to use with EarthLink Mobile. To find a device’s IMEI, dial <b>*#06#</b> on that device. This step is not required to continue.</p>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
          form={form}>
          <Row>
            <Col span={10} className="compatible-input responsive-width-set">
              <Form.Item
                label="IMEI Number"
                name="imei"
                rules={[{ required: true, message: 'Please enter IMEI Number' }, {
                  min: 15,
                  message: 'IMEI number should contain 15-17 digits.'
                }]}
              >
                <Input type="text" className="input-control" name="imei" id="imei" placeholder="Enter 15-17 digit IMEI value"
                  disabled={loading}
                  onChange={onChangehandler}
                />
              </Form.Item>
              {msg?  <div className={`result-compatible ${resultColor()}`}>
                {msg === 'Compatible' ? <i className="fa-regular fa-circle-check"></i> : <i className="fa-regular fa-circle-xmark"></i> }
                <span id="resultarea">{!loading && msg ? msg : ''}</span>
              </div> : <></>}
            </Col>

            {loading ?
              <Loader />
              :
              <>
                {msg ?
                  <Col span={12} className="responsive-width-set check-another">
                    <>
                      <button className="blue-button" onClick={() => {
                        setMsg('')
                        form.setFieldsValue({ imei: '' })
                      }}>Check Another Device</button>
                      <div> OR </div>
                      <button className="blue-button grey-button" onClick={() => props.setActiveKey('3')}>Skip</button>
                    </>
                  </Col>
                  :
                  <Col span={8} className="responsive-width-set only-check check-another">
                    <button type="primary" htmlType="submit" className="Check blue-button">
                      Submit
                    </button>
                    <button className="blue-button grey-button" onClick={() => props.setActiveKey('3')}>Skip</button>
                  </Col>
                }
              </>
            }
          </Row>
        </Form>
      </div>
    </>
  )
}
export default CompatibilityCheck;
