import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Footer from '../components/Layout/Footer';
import HeaderMain from '../components/Layout/Header';
import HomePage from '../components/Order/HomePage';
import Product from '../components/Order/Products/Product';
import { routesConfig } from '../util/util';
import Checkout from '../components/Order/Checkout/CheckOut'
import CartInformation from '../components/common/CartInformation';
import ThankYou from '../components/Order/ThankYou';
import VasPrdoduct from '../components/Order/Checkout/VasProducts';
import VasStatic from '../components/Order/VasStatic';
import Partner from '../components/entry/Partner';



const AppRoutes = (props) => {
  return (
    <>
      <HashRouter>
        <HeaderMain />
        <Routes>
          <Route exact {...props} path={routesConfig.home} element={<HomePage {...props} />} />
          <Route exact {...props} path={routesConfig.products} element={<Product />} />
          <Route exact {...props} path={routesConfig.checkout} element={<Checkout />} />
          <Route exact {...props} path={routesConfig.reviewOrder} element={<CartInformation />} />
          <Route exact {...props} path={routesConfig.thanks} element={<ThankYou />} />
          <Route exact {...props} path={routesConfig.vasPage} element={<VasPrdoduct />} />
          <Route exact {...props} path={routesConfig.vas} element={<VasStatic />} />
          <Route exact {...props} path={routesConfig.partner} element={<Partner />} />
        </Routes>
        <Footer />
      </HashRouter>
    </>
  )
}
export default AppRoutes;
