import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import 'antd/es/select/style/index.css';
import {
  CloseCircleFilled,
  EnvironmentOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Row, Col, Select, Input, Button, Modal } from 'antd';
import UnitType from '../../helpers/UnitType';
import {
  getAggregatedAddress,
  getSegregatedAddress,
} from '../../common/common';

const VerifyAddressModal = (props) => {
  const { loading } = props;
  const dispatch = useDispatch();
  const [unitAddress, setUnitAddress] = useState({
    unitType: '',
    unitNumber: '',
    secondUnitType: '',
    secondUnitNumber: '',
  });
  const [showSecondAddressLine, setShowSecondAddressLine] = useState(false);
  const { unitType, unitNumber, secondUnitType, secondUnitNumber } =
    unitAddress;
  const address2 = `${unitType} ${unitNumber} ${secondUnitType} ${secondUnitNumber}`;
  const { address1, city, state, zip } = getSegregatedAddress(
    props?.searchAddress
  );
  const newManualAddress = {
    address1,
    address2: address2?.trimEnd(),
    city,
    zip,
    state,
  };
  const manualAddressValue =
    (newManualAddress?.address2?.length &&
      getAggregatedAddress(newManualAddress)) ||
    '';

  const handleResetService = useCallback(() => {
    if (!loading) {
      props?.setIsAddressInfoEditVisible(false);
    }
  }, [props, dispatch, loading]);

  const handleThirdAddress = useCallback(() => {
    setShowSecondAddressLine(!showSecondAddressLine);
    if (!showSecondAddressLine) {
      setUnitAddress({
        ...unitAddress,
        secondUnitNumber: '',
        secondUnitType: '',
      });
    }
  }, [showSecondAddressLine, unitAddress]);

  const disabledSubmit = useCallback(() => {
    if (unitNumber?.length <= 0 || unitType?.length <= 0) {
      return true;
    }
    if (showSecondAddressLine) {
      if (secondUnitNumber?.length <= 0 || secondUnitType?.length <= 0) {
        return true;
      }
    }
    return false;
  }, [showSecondAddressLine, unitAddress]);

  const handleInputChange = useCallback(
    (e) => {
      const { value, name } = e.target;
      setUnitAddress({ ...unitAddress, [name]: value?.trimStart() });
    },
    [unitAddress]
  );

  const handleSubmit = useCallback(() => {
    props?.handleSubmit(manualAddressValue, false);
  }, [props, manualAddressValue]);

  return (
    <Modal
      visible={props?.isAddressInfoEditVisible}
      onCancel={handleResetService}
      title="Confirm Address Details and Type"
      footer={null}
      className="address-modal"
      maskClosable={false}
      width={620}
    >
      <div className="address-header">
        <EnvironmentOutlined className="location-icon" />
        <div className="address-text">{props?.searchAddress}</div>
        <EditOutlined
          alt="Edit"
          onClick={handleResetService}
          className="edit-address-icon"
        />
      </div>
      <h4>
        <b>This address has multiple units</b>
      </h4>
      <div className="row-container">
        <Row gutter={[10, 10]}>
          <Col span={8}>
            <label className="address-label">Select Unit Type</label>
            <br />
            <Select
              allowClear
              showSearch
              placeholder="APT, UNIT, LOT, SPC"
              value={unitType ? unitType : 'APT, UNIT, LOT, SPC'}
              onSelect={(value) => {
                setUnitAddress({ ...unitAddress, unitType: value });
              }}
            >
              {UnitType?.map(({ code, name }) => (
                <Select.Option key={code} value={code}>
                  {code} - {name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={8}>
            <label className="address-label">Enter Unit Number</label>
            <br />
            <Input
              type="text"
              placeholder="12, 4G, 210"
              name="unitNumber"
              onChange={handleInputChange}
              value={unitNumber}
              maxLength={6}
            />
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          {showSecondAddressLine && (
            <>
              <Col span={8}>
                <label className="address-label">Select Second Unit Type</label>
                <br />
                <Select
                  allowClear
                  showSearch
                  placeholder="APT, UNIT, LOT, SPC"
                  value={
                    secondUnitType ? secondUnitType : 'APT, UNIT, LOT, SPC'
                  }
                  onSelect={(value) => {
                    setUnitAddress({ ...unitAddress, secondUnitType: value });
                  }}
                  disabled={unitNumber?.length <= 0 || unitType?.length <= 0}
                >
                  {UnitType?.map(({ code, name }) => (
                    <Select.Option key={code} value={code}>
                      {code} - {name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col span={8}>
                <label className="address-label">Enter Unit Number</label>
                <br />
                <Input
                  type="text"
                  placeholder="12, 4G, 210"
                  name="secondUnitNumber"
                  onChange={handleInputChange}
                  value={secondUnitNumber}
                  maxLength={6}
                  disabled={unitNumber?.length <= 0 || unitType?.length <= 0}
                />
              </Col>
            </>
          )}
          <Col style={{ marginTop: showSecondAddressLine ? '25px' : '0px' }}>
            <a onClick={handleThirdAddress}>
              {showSecondAddressLine ? (
                <CloseCircleFilled className="close-circle-icon" />
              ) : (
                'Add Another'
              )}
            </a>
          </Col>
        </Row>
        <div className="button-container">
          <Button
            htmlType="reset"
            className="cancel-btn"
            onClick={handleResetService}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={disabledSubmit()}
            onClick={handleSubmit}
            className="submit-btn"
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default VerifyAddressModal;
