import React, { useState, useEffect } from 'react';
import { Form, Input, Checkbox, Row, Col } from 'antd';
import {
  getAggregatedAddress,
  handleAlphaKeyPress,
  handleNumericKeyPress,
  isUndefinedNullOrEmpty,
} from '../../../common/common';
import { ValidationMessages } from '../../../common/validationMessages';
import {
  chasePaymentUrls,
  OrderStatus,
  routesConfig,
} from '../../../util/util';
import { nameRegex } from '../../../util/regex';
import InputMask from 'react-input-mask';
import {
  useCartData,
  useLoading,
  useServiceablitityData,
  useSessionData,
  useVasData,
} from '../../../hooks/order';
import AddressSuggestionSelect from '../../common/AddressSuggestionSelect';
import {
  validateCreditCard,
  updateOrder,
  updateErrorMsg,
  savePaymentInfo,
} from '../../../store/action/OrderAction';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { getPaymentInfoModel } from '../../../helpers/OrderHelperFunctions';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../common/Loader';
import { getConfigs } from '../../../config';

const config = getConfigs();

const PaymentDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [maskedCcn, setMaskedCcn] = useState('');
  const [maskedCvc, setMaskedCvc] = useState('');
  const [showBorder, setShowBorder] = useState(false);
  const { orderInfo } = useSessionData();
  const { normalizedAddress } = useServiceablitityData();
  const { loading } = useLoading();
  const { isVasOnlyFlow } = useVasData();
  const { isMobileContainer, servRef } = useCartData();
  const { address1, address2, city, state, zipCode } = normalizedAddress;
  const {
    id,
    billingAddress,
    serviceAddress,
    billingAdd1,
    billingAdd2,
    billingCity,
    billingState,
    billingZip,
    firstName,
    lastName
  } = orderInfo;
  const [isAddressSame, setisAddressSame] = useState(
    isUndefinedNullOrEmpty(billingAddress)
      ? true
      : billingAddress === serviceAddress
  );
  const [billingAddrState, setBillingState] = useState({
    billAdd1: isUndefinedNullOrEmpty(billingAdd1)
      ? normalizedAddress.address1
      : billingAdd1,
    billAdd2: isUndefinedNullOrEmpty(billingAdd2)
      ? normalizedAddress.address2
      : billingAdd2,
    billCity: isUndefinedNullOrEmpty(billingCity)
      ? normalizedAddress.city
      : billingCity,
    billState: isUndefinedNullOrEmpty(billingState)
      ? normalizedAddress.state
      : billingState,
    billZip: isUndefinedNullOrEmpty(billingZip)
      ? normalizedAddress.zipCode
      : billingZip,
    billAddress: isUndefinedNullOrEmpty(billingAddress)
      ? getAggregatedAddress(normalizedAddress)
      : billingAddress,
  });
  const { billAdd1, billAdd2, billCity, billState, billZip } = billingAddrState;
  const [agreement, setAgreement] = useState(false);
  const fullName = `${firstName} ${lastName}`;

  useEffect(() => {
    if (isAddressSame) {
      form.setFieldsValue({
        serviceAddress: getAggregatedAddress({
          address1: billAdd1,
          address2: billAdd2,
          city: billCity,
          state: billState,
          zipCode: billZip,
        }),
      });
      form.setFieldsValue({ expirationDate: '' });
    }
  }, []);

  const chasePaymentUrl = {
    encryption: chasePaymentUrls.encryption,
    getKey: chasePaymentUrls.getKey,
  };

  const loadScript = (url) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.async = false;
      script.src = url;
      const body = document.body;
      body.appendChild(script);
      script.addEventListener('load', resolve, {
        once: true,
      });
    });
  };

  useEffect(() => {
    Promise.all(Object.values(chasePaymentUrl).map(loadScript));
  }, []);

  /* These two functions below check wether Chase Paymentech scripts loaded,
   and the third performs Encryption **/
  // eslint-disable-next-line camelcase
  const is_pie_key_download_error = () => {
    if (
      typeof window.PIE === 'undefined' ||
      typeof window.PIE.K === 'undefined' ||
      typeof window.PIE.L === 'undefined' ||
      typeof window.PIE.E === 'undefined' ||
      typeof window.PIE.key_id === 'undefined' ||
      typeof window.PIE.phase === 'undefined'
    ) {
      return true;
    }
    return false;
  };

  // eslint-disable-next-line camelcase
  const is_pie_encryption_download_error = () => {
    if (
      typeof window.ValidatePANChecksum !== 'function' ||
      typeof window.ProtectPANandCVV !== 'function'
    ) {
      return true;
    }
    return false;
  };

  const doEncryption = () => {
    if (is_pie_encryption_download_error()) {
      throw new Error(
        'Sorry, We are currently unable to process the credit card at this time. Please call or try again later.'
      );
    }

    if (is_pie_key_download_error()) {
      throw new Error(
        'Sorry, We are currently unable to process the credit card at this time. Please call or try again later.'
      );
    }

    if (!window.ValidatePANChecksum(maskedCcn)) {
      throw new Error('Card is Invalid');
    }

    const encryptedKey = window.ProtectPANandCVV(maskedCcn, maskedCvc, false);

    if (encryptedKey === null || encryptedKey === undefined) {
      throw new Error(
        'Card unable to be processed at this time. Most likely due to Invalid card, please call or try again later.'
      );
    }
    return {
      encryptedCcn: encryptedKey[0],
      encryptedCvc: encryptedKey[1],
      integrityCheck: encryptedKey[2],
    };
  };

  const handleContinue = (values) => {
    const { cardHolderName, expirationDate, ccn, cvc } = values;
    const customerAgreementTimestamp = moment().format('lll');
    if (!isUndefinedNullOrEmpty(id)) {
      try {
        const { encryptedCcn, encryptedCvc, integrityCheck } = doEncryption();
        const agentReadTC = values.agreement;
        const finalBillAddress = getAggregatedAddress({
          address1: billAdd1,
          address2: billAdd2,
          city: billCity,
          state: billState,
          zipCode: billZip,
        });
        const finalServiceAddress = getAggregatedAddress({
          address1,
          address2,
          city,
          state,
          zipCode,
        });
        const customerAcceptedTC = false;
        const expireDate = values.expirationDate.replace(/\/20/, '/');

        dispatch(
          validateCreditCard({
            creditCardNumber: encryptedCcn + '||' + integrityCheck,
            expirationDate: expireDate,
            csvCode: encryptedCvc,
            applClient: 'ELNKWWW',
          })
        )
          .then((response) => {
            if (response.value.data.retCode.toLowerCase() === 'valid') {
              let paymentModel = getPaymentInfoModel({
                agentReadTC,
                customerAcceptedTC,
                maskedCcn,
                ccn,
                encryptedCcn,
                maskedCvc,
                cvc,
                encryptedCvc,
                cardHolderName,
                expirationDate,
                integrityCheck,
              });
              dispatch(savePaymentInfo(paymentModel));

              dispatch(
                updateOrder(
                  {
                    orderStatus: OrderStatus.ccInfo,
                    disclaimerRead: customerAgreementTimestamp,
                    autoPaymentAgreed: customerAgreementTimestamp,
                    serviceError: null,
                    billingAdd1:
                      isAddressSame && !isVasOnlyFlow && !isMobileContainer
                        ? address1
                        : billAdd1,
                    billingAdd2:
                      isAddressSame && !isVasOnlyFlow && !isMobileContainer
                        ? address2
                        : billAdd2,
                    billingAddress:
                      isAddressSame && !isVasOnlyFlow && !isMobileContainer
                        ? finalServiceAddress
                        : finalBillAddress,
                    billingCity:
                      isAddressSame && !isVasOnlyFlow && !isMobileContainer
                        ? city
                        : billCity,
                    billingState:
                      isAddressSame && !isVasOnlyFlow && !isMobileContainer
                        ? state
                        : billState,
                    billingZip:
                      isAddressSame && !isVasOnlyFlow && !isMobileContainer
                        ? zipCode
                        : billZip,
                    serviceReference: servRef,
                  },
                  id
                )
              )
                .then(() => {
                  dispatch(updateErrorMsg(''));
                  navigate(routesConfig.reviewOrder);
                })
                .catch((err) => {
                  dispatch(updateErrorMsg(err));
                });
            } else {
              dispatch(
                updateOrder(
                  {
                    serviceError:
                      response?.value?.data?.extRetCode ||
                      'invalid credit card',
                  },
                  id
                )
              );
              form.setFields([
                {
                  name: 'ccn',
                  errors: [
                    `${
                      response?.value?.data?.extRetCode || 'invalid credit card'
                    }`,
                  ],
                },
              ]);
            }
          })
          .catch(() => {
            dispatch(
              updateOrder(
                {
                  serviceError: 'cc-validate failed',
                },
                id
              )
            );
            form.setFields([
              {
                name: 'ccn',
                errors: ['Invalid Credit Card'],
              },
            ]);
          });
      } catch (error) {
        form.setFields([
          {
            name: 'ccn',
            errors: [
              error?.message
                ? error.message
                : 'We are currently unable to process the credit card at this time. Please call or try again later.',
            ],
          },
        ]);
        return;
      }
    }
  };

  const maskInput = (e, input) => {
    const { value } = e.target;
    if (input === 'ccn') {
      if(value.includes('*')===false){
        setMaskedCcn(value);
      }else{
        setMaskedCcn(maskedCcn);
      }
    }
    if (input === 'cvc') {
      setMaskedCvc(value);
    }
    let charsCount = value.length;
    let inputVal = value;
    let part1 = '',
      part2 = '';
    let str = '*';

    if (charsCount > 13 && input === 'ccn') {
      part1 = str.repeat(charsCount - 4);
      part2 = inputVal.substr(charsCount - 4, charsCount - 1);
      form.setFieldsValue({
        [input]: part1 + part2,
      });
    }
    if (charsCount > 2 && input === 'cvc') {
      part1 = str.repeat(charsCount - 0);
      part2 = inputVal.substr(charsCount - 0);
      form.setFieldsValue({
        [input]: part1 + part2,
      });
    }

    if (value !== '' && value.length > 13 && input === 'ccn') {
      if (
        typeof window.ValidatePANChecksum === 'function' &&
        typeof window.ProtectPANandCVV === 'function'
      ) {
        const ccnVal = value.includes('*') ? maskedCcn : value;
        if (!window.ValidatePANChecksum(ccnVal)) {
          form.setFields([
            {
              name: 'ccn',
              errors: [`${ValidationMessages.invalidCCN}`],
            },
          ]);
        }
      }
    }
  };

  const onCCNChange = (e) => {
    const filteredValue = e.target.value.replace(/\D/gi, '').slice(0, 19);
    if(filteredValue.includes('*')===false){
      form.setFieldsValue({ ccn: filteredValue });
      setMaskedCcn(filteredValue);
    }
    form.setFieldsValue({ ccn: filteredValue });
  };

  const onCVCChange = (e) => {
    const filteredValue = e.target.value.replace(/\D/gi, '');
    form.setFieldsValue({ cvc: filteredValue });
  };

  const setBilling = (searchAddress) => {
    setBillingState({
      ...billingAddrState,
      billAdd1: searchAddress.address1,
      billAdd2: searchAddress.address2,
      billCity: searchAddress.city,
      billState: searchAddress.state,
      billZip: searchAddress.zip,
    });
    form.setFieldsValue({
      serviceAddress: getAggregatedAddress({
        address1: searchAddress.address1,
        address2: searchAddress.address2,
        city: searchAddress.city,
        state: searchAddress.state,
        zipCode: searchAddress.zip,
      }),
    });
  };

  const addressSync = (event) => {
    if (event.target.checked) {
      setBillingState({
        ...billingAddrState,
        billAdd1: address1,
        billAdd2: address2,
        billCity: city,
        billState: state,
        billZip: zipCode,
      });
      form.setFieldsValue({
        serviceAddress: getAggregatedAddress({
          address1: address1,
          address2: address2,
          city: city,
          state: state,
          zipCode: zipCode,
        }),
      });
    } else {
      setBillingState({
        ...billingAddrState,
        billAdd1: isUndefinedNullOrEmpty(billingAdd1) ? '' : billingAdd1,
        billAdd2: isUndefinedNullOrEmpty(billingAdd2) ? '' : billingAdd2,
        billCity: isUndefinedNullOrEmpty(billingCity) ? '' : billingCity,
        billState: isUndefinedNullOrEmpty(billingState) ? '' : billingState,
        billZip: isUndefinedNullOrEmpty(billingZip) ? '' : billingZip,
        billAddress: isUndefinedNullOrEmpty(billingAddress)
          ? ''
          : billingAddress,
      });
      form.setFieldsValue({ serviceAddress: '' });
    }
    setisAddressSame(event.target.checked);
  };

  return (
    <>
      <div className="your-info-tab payment-info same-common">
        <Form
          name="basic"
          initialValues={{ required: true, cardHolderName:fullName }}
          onFinish={handleContinue}
          autoComplete="off"
          form={form}
          validateTrigger={'onBlur'}
        >
          <Row className="yor-info-row j-between">
            <Col span={15} className="your-info-input responsive-width-set">
              <Form.Item
                label="Card Number"
                name="ccn"
                rules={[
                  { required: true, message: 'Please enter Card Number' },
                  {
                    min: 13,
                    message: `${ValidationMessages.min13Length}`,
                  },
                  {
                    validator(_, value) {
                      if (value && value.startsWith('000000000')) {
                        return Promise.reject(
                          new Error(`${ValidationMessages.invalidCCN}`)
                        );
                      }
                      if (
                        !isUndefinedNullOrEmpty(value) &&
                        value !== '' &&
                        value.length > 13
                      ) {
                        if (
                          typeof window.ValidatePANChecksum === 'function' &&
                          typeof window.ProtectPANandCVV === 'function'
                        ) {
                          const ccnVal = value.includes('*')
                            ? maskedCcn
                            : value;
                          if (!window.ValidatePANChecksum(ccnVal)) {
                            return Promise.reject(
                              new Error(`${ValidationMessages.invalidCCN}`)
                            );
                          }
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  id="credit_nr"
                  data-protected={true}
                  minLength={13}
                  maxLength={19}
                  onBlur={(e) => maskInput(e, 'ccn')}
                  onFocus={() => {
                    form.setFieldsValue({ ccn: maskedCcn });
                  }}
                  onKeyPress={handleNumericKeyPress}
                  onChange={(e) => onCCNChange(e)}
                />
              </Form.Item>
            </Col>
            <Col span={9} className="your-info-input responsive-width-set">
              <Form.Item
                label="Card Security Code"
                name="cvc"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Card Security Code',
                  },
                  {
                    min: 3,
                    message: `${ValidationMessages.min3Length}`,
                  },
                ]}
              >
                <Input
                  maxLength="4"
                  minLength="3"
                  onBlur={(e) => maskInput(e, 'cvc')}
                  onFocus={() => {
                    form.setFieldsValue({ cvc: maskedCvc });
                  }}
                  onKeyPress={handleNumericKeyPress}
                  onChange={(e) => onCVCChange(e)}
                />
              </Form.Item>
            </Col>
            <Col span={15} className="your-info-input responsive-width-set">
              <Form.Item
                label="Name on Card"
                name="cardHolderName"
                rules={[
                  { required: true, message: 'Please enter Full Name' },
                  {
                    min: 2,
                    message: `${ValidationMessages.min2Length}`,
                  },
                  {
                    validator(_, value) {
                      if (!isUndefinedNullOrEmpty(value)) {
                        if (!nameRegex.test(value)) {
                          return Promise.reject(
                            new Error('Please enter a valid Full Name')
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  onKeyPress={handleAlphaKeyPress}
                />
              </Form.Item>
            </Col>
            <Col span={9} className="your-info-input responsive-width-set">
              <Form.Item
                label="Expiration Date"
                name="expirationDate"
                hasFeedback
                rules={[
                  { required: true, message: '', whitespace: true },
                  {
                    validator(_, value) {
                      if (isUndefinedNullOrEmpty(value)) {
                        return Promise.reject(
                          new Error('Please enter Expiration Date')
                        );
                      }
                      if (
                        !isUndefinedNullOrEmpty(value) &&
                        (value === '__/____' || value.includes('_'))
                      ) {
                        return Promise.reject(
                          new Error('Please enter Expiration Date')
                        );
                      }
                      if (
                        value &&
                        value.trim() !== '' &&
                        value !== '__/____' &&
                        !value.includes('_')
                      ) {
                        let date = [];
                        let month = Number(new Date().getMonth());
                        let year = Number(new Date().getFullYear());
                        date = value.split('/');
                        date.map((d) => {
                          return Number(d);
                        });
                        let mm = Number(date[0]);
                        let yyyy = Number(date[1]);
                        if (
                          mm > 12 ||
                          yyyy < year ||
                          yyyy > year + 25 ||
                          (yyyy === year && mm <= month) ||
                          mm < 1
                        ) {
                          return Promise.reject(
                            new Error(`${ValidationMessages.invalidExpDate}`)
                          );
                        }
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <InputMask
                  mask="99/9999"
                  className="ant-input"
                  name="expirationDate"
                  id="expirationDate"
                />
              </Form.Item>
            </Col>
            <Col span={24} className="your-info-input location-icon ">
              <Form.Item
                className="address-on-card"
                name="serviceAddress"
                initialValue={getAggregatedAddress({
                  address1: billingAdd1,
                  address2: billingAdd2,
                  city: billingCity,
                  state: billingState,
                  zipCode: billingZip,
                })}
                rules={[
                  { required: true, message: 'Please enter billing address' },
                ]}
              >
                {isAddressSame && !isVasOnlyFlow && !isMobileContainer ? (
                  <Input disabled />
                ) : (
                  <AddressSuggestionSelect
                    autoFocus={false}
                    autoComplete="off"
                    parentComp="payment"
                    className="billingInput"
                    setBilling={setBilling}
                    val={billingAddress}
                    name="serviceAddress"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          {/* <Row className="zip-code-row">
                        <Col span={16} className="d-flex al-center">
                            <Row align="middle">
                                <Col span={4} className="fourg-image">
                                    <img src={fourgpoor} />
                                </Col>
                                <Col span={18}>
                                    <b>Please note:</b> Mobile coverage at this address is considered “Poor”. Enter a zip code to check a wider area of coverage.
                                </Col>
                                <Col span={2}></Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row>
                                <Col span={12} className="zip-cdoe-col">
                                    <Form.Item
                                        label="Zip Code"
                                        name="ZipCode"
                                        rules={[{ required: false, message: 'Please enter Zip Code' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="zip-code-check-btn">
                                    <button className="blue-button">Check</button>
                                </Col>
                            </Row>
                        </Col>
                    </Row> */}
          {!isVasOnlyFlow && !isMobileContainer && (
            <Checkbox
              className="agree-billing-address"
              onClick={() => setisAddressSame(!isAddressSame)}
              checked={isAddressSame}
              onChange={addressSync}
            >
              Billing address is the same as service address
            </Checkbox>
          )}

          <div className={`m-top-0-5 ${showBorder ? 'border-red' : ''}`}>
            <Form.Item
              name="agreement"
              valuePropName="checked"
              className="agree_checkbox"
              rules={[
                {
                  validator: (_, value) => {
                    if (value) {
                      setShowBorder(false);
                      return Promise.resolve();
                    } else {
                      setShowBorder(true);
                      return Promise.reject(new Error(''));
                    }
                  },
                },
              ]}
            >
              <Checkbox
                onClick={() => setAgreement(!agreement)}
                disabled={loading}
              >
                You&apos;re agreeing to EarthLink&apos;s{' '}
                <a
                  href={`${config.NEXT_BASE_URL}/tcs/?apa=1/`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Automatic Payments Authorization
                </a>{' '}
                and storage of the Method of Payment for future account
                transactions. By continuing, you confirm your understanding of
                this.
              </Checkbox>
            </Form.Item>
          </div>

          <div className="text-center enter-your-info payment-submit">
            {loading ? (
              <Loader />
            ) : (
              <button
                className="blue-button"
                htmlType="submit"
              >
                Review Order
              </button>
            )}
            <p>You can review your order before submitting payment.</p>
          </div>
        </Form>
      </div>
    </>
  );
};
export default PaymentDetail;
