import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Col, Select, Popover } from 'antd';
import { getValidIntCost, getWirelessServRef } from '../../../common/common';
import {
  useMbox,
  useServiceablitityData,
  useCartData,
  useCallCenterFlag,
} from '../../../hooks/order';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import {
  getServicePropsByCode,
  saveInternetPlan,
  saveVasPlanInfo,
  setMBoxReqObj,
  setSelectedBundle,
  saveWirelessPlanInfo
} from '../../../store/action/OrderAction';
import {
  EECproductAddToCart,
  EECproductRemoveFromCart,
} from '../../../common/GTMScripts';
import {
  DashboardOutlined,
  CustomerServiceOutlined,
  InfoCircleOutlined,
  MobileOutlined
} from '@ant-design/icons';
import { RenderTfnButton } from '../../Layout/RenderTfnButton';
import { getConfigs } from '../../../config';
const config = getConfigs();

const BundlePlans = (props) => {
  const {
    index,
    plans,
    product,
    allProducts,
    mobileProducts,
    setBundlePrices,
    bundlePrices,
    setIsDescriptionBoxOpened, isDescriptionBoxOpened, mobileProductServRef,getProductPrice
  } = props;
  const { serviceabilityCheck } = useServiceablitityData();
  const { vasProducts } = serviceabilityCheck;
  const { selectedBundleData, isBundleAdded, vasPlanInfo, internetPlan = {}, wirelessCartInfo, isInternetAdded, serviceTerm, getContractLength } = useCartData();
  const [selectedInternet, setSelectedInternet] = useState(
    isInternetAdded ? internetPlan : plans[0]
  );
  const dispatch = useDispatch();
  const { mboxRequired } = useMbox();
  const {showCallNow} = useCallCenterFlag();
  const [selectedMobile, setSelectedMobile] = useState(mobileProducts[0])
  const showTermContract = config.SHOW_TERM_CONTRACT === 'true';

  const selectedMobServref = useMemo(() => {
    const mobServRef =
      product.productsServRef?.includes('mobile*') &&
      mobileProductServRef?.split(',')?.length === product.productsServRef?.length
        ? mobileProductServRef?.split(',') || []
        : mobileProducts.length > 0
          ? new Array(product.productsServRef?.length).fill(mobileProducts[0]?.servRef).flat()
          : [];
    return mobServRef;
  }, [mobileProductServRef]);


  const getBundleProducts = useCallback(() => {
    const filteredProducts = allProducts.filter(e =>
      product.productsServRef?.includes(e.servRef),
    );
    if (product.productsServRef?.includes('ipbb*')) {
      filteredProducts.push(selectedInternet);
    }
    if (product.productsServRef?.includes('mobile*')) {
      if (selectedMobServref.length > 0) {
        const mobileProduct = selectedMobServref.map(item => {
          return allProducts.find((e) => item === e.servRef) || {};
        });
        const mobProd = R.uniq(mobileProduct);
        const final = mobProd.map(item => {
          return { ...item, quantity: selectedMobServref?.filter(e => e === item?.servRef)?.length || 1 };
        });
        return final;
      }
    } else {
      const final = filteredProducts.map(item => {
        return { ...item, quantity: product?.productsServRef?.filter(e => e === item.servRef)?.length || 1 };
      });
      return final.reverse();
    }
    return [];
  }, [allProducts, product, selectedInternet, selectedMobServref, selectedMobile]);

  const getBundlePrice = useCallback(() => {
    let finalPrice = 0;
    const productsArr = getBundleProducts();
    const productPrice =
      Number(
        R.sum(productsArr.map((d) => getValidIntCost(getProductPrice(d, serviceTerm)) * (d?.quantity || 1))).toFixed(2)
      ) || 0;
    finalPrice = Number(productPrice + Number(product?.creditAmount)).toFixed(
      2
    );
    return finalPrice;
  }, [getBundleProducts(), product?.creditAmount]);

  const showAllIPBB = useMemo(() => {
    for (const i of [1, 2, 3, 4].keys()) {
      if (
        product[`servLevel${i}`] === '*' &&
        product[`servCode${i}`]?.toString()?.toLowerCase() === 'ipbb'
      ) {
        return true;
      }
    }
  }, [product]);

  const showAllMob = useMemo(() => {
    for (const i of [1, 2, 3, 4].keys()) {
      if (
        product[`servLevel${i}`] === '*' &&
        product[`servCode${i}`]?.toString()?.toLowerCase() === 'mobile'
      ) {
        return true;
      }
    }
  }, [product, selectedMobServref]);

  useEffect(() => {

    let priceVal = Number(getBundlePrice());

    if (!bundlePrices?.includes(priceVal)) {

      setBundlePrices((prev) => [...prev, priceVal]);

    }
  }, [getBundlePrice(), bundlePrices, setBundlePrices]);

  const isBundleAddedCart = useMemo(() => {
    return isBundleAdded &&
    selectedBundleData?.bundleCode === product?.bundleCode
      ? true
      : false;
  }, [product, isBundleAdded, selectedBundleData]);

  const BundleProducts = useMemo(() => {
    return getBundleProducts();
  }, [getBundleProducts()]);

  const BundleMobileProduct = useMemo(() => {
    return (
      BundleProducts.find((e) => e?.technology?.toLowerCase() === 'mobile') ||
      null
    );
  }, [BundleProducts]);

  const BundleVasProduct = useMemo(() => {
    return (
      BundleProducts.find((e) => e?.technology?.toLowerCase() === 'vas') || null
    );
  }, [BundleProducts]);

  const serviceDescPoints = useMemo(() => {
    return selectedInternet?.serviceDesc?.split('. ') || [];
  }, [selectedInternet]);
  const handleMobileSelectChange = (ind, isSelected) => {
    setSelectedMobile((prevState) => {
      const updatedMobile = mobileProducts.find(
        (p) => p?.servRef === isSelected
      ) || prevState;
      return { ...prevState, ...updatedMobile };
    });
    if (isSelected) {
      selectedMobServref[ind] = isSelected;
    }
  };

  const removePreviousProducts = (item) => {
    if (item?.technology?.toLowerCase() === 'dsl') {
      delete mboxRequired[product?.servCode];
      dispatch(setMBoxReqObj(mboxRequired));
      EECproductRemoveFromCart(item);
      dispatch(saveInternetPlan({}));
    }
    if (item?.technology?.toLowerCase() === 'vas') {
      let mboxReq = mboxRequired;
      vasPlanInfo?.vasPlan?.map((plan, i) => {
        delete mboxReq[plan.servCode];
        dispatch(setMBoxReqObj(mboxReq));
        vasPlanInfo.vasPlan.splice(i, 1);
        vasPlanInfo?.vasProductCart?.splice(i, 1);
        dispatch(
          saveVasPlanInfo({
            vasPlan: vasPlanInfo.vasPlan,
            vasProductCart: vasPlanInfo.vasProductCart,
          })
        );
        EECproductRemoveFromCart(plan);
      });
    }
    if (item?.technology?.toLowerCase() === 'mobile') {
      const selectedWirelessProducts = wirelessCartInfo?.wirelessPlanInfo;
      const mobIndex = selectedWirelessProducts?.findIndex(el => el.servRef === item.servRef);
      selectedWirelessProducts?.splice(mobIndex, 1);
      dispatch(saveWirelessPlanInfo({
        wirelessPlanInfo: selectedWirelessProducts,
        wirelesServeRef: getWirelessServRef(selectedWirelessProducts)
      }));
      EECproductRemoveFromCart(item);
    }
  };
  const createBundle = (mboxReq, productsArr, vasValue) => {
    const mob = [];
    productsArr.forEach((item) => {
      if (item.technology.toLowerCase() === 'dsl') {
        dispatch(getServicePropsByCode(item.servCode)).then((res) => {
          mboxRequired[item.servCode] =
            res.action?.payload?.data?.item?.isMboxReq;
          dispatch(setMBoxReqObj(mboxRequired));
        });
        dispatch(saveInternetPlan(item));
        EECproductAddToCart(item);
      } else if (item.technology.toLowerCase() === 'vas') {
        if (vasProducts[vasValue].servCode === 'onlnbckp') {
          mboxReq.onlnbckp = true;
          dispatch(setMBoxReqObj(mboxReq));
        } else {
          let vasVal = 0;
          dispatch(getServicePropsByCode(vasProducts[vasVal].servCode)).then(
            (res) => {
              mboxReq[vasProducts[vasVal].servCode] =
                res.action?.payload?.data?.item?.isMboxReq;
              dispatch(setMBoxReqObj(mboxReq));
            }
          );
        }
        let vasProductCartVal;
        if (vasProducts && vasProducts.length) {
          vasProducts.forEach((el, i) => {
            if (el?.servRef === BundleVasProduct?.servRef) {
              vasProductCartVal = i;
            }
          });
        }
        dispatch(
          saveVasPlanInfo({
            vasPlan: [BundleVasProduct],
            vasProductCart: [vasProductCartVal],
          })
        );
      }
      if (item?.technology?.toLowerCase() === 'mobile') {
        item.quantity = item.quantity ? item.quantity : 1;
        mob.push(item);
      }
    });
    if (mob.length > 0) {
      dispatch(saveWirelessPlanInfo({
        wirelessPlanInfo: mob,
        wirelesServeRef: getWirelessServRef(mob)
      }));
    }
  };
  const addBundleToCart = () => {
    let vasValue = 0;

    if (isBundleAddedCart) {
      // if products already added then remove them and clear bundle
      BundleProducts.forEach((item) => {
        removePreviousProducts(item);
      })
      dispatch(setSelectedBundle(null));
    } else {
      let mboxReq = mboxRequired;
      createBundle(mboxReq, BundleProducts, vasValue);
      dispatch(setSelectedBundle(product));
    }
  };

  const showMostPopular = (prod) => {
    if (prod.topRibbon) {
      return 'mostPop';
    }
    return '';
  }

  const getBulletIcon = (val) => {
    if (val?.toLowerCase()?.includes('mobile')) {
      return <MobileOutlined className="font-blue font-20" />
    } else {
      return <CustomerServiceOutlined className="font-blue font-20" />
    }
  }

  const popupText = useCallback(item => {
    if (item?.technology?.toLowerCase() === 'vas') {
      return item?.serviceName?.replace('EarthLink ', '')?.split('x')[0];
    }
    if (item?.technology?.toLowerCase() === 'mobile') {
      return `${item?.quantity} * ${item?.serviceName}`;
    }
    return item.serviceName
      .replace('High ', '')
      .replace('Speed ', '')
      .replace('Internet ', '')
      .split('x')[0];
  }, []);
  return (
    <Col
      span={12}
      className={`plan-single ${showMostPopular(product)}`}
      key={index}
    >
      <div
        className={`plan-single-inner  ${isDescriptionBoxOpened === product?.bundleCode && isDescriptionBoxOpened !== '-1' ? 'Seeless' : ''
        }`}
      >
        <div className="bundle-info">
          <h4>${getBundlePrice()}/mo</h4>
          {showTermContract && showAllIPBB && (<p>{`${getContractLength(BundleProducts)} Month Agreement`}</p>)}
          <p>{product?.bundleName}
            <Popover
              trigger="hover"
              title=""
              overlayStyle={{ maxWidth: '400px', minWidth: '250px' }}
              content={
                <ul className="tol-tip-summery">
                  {BundleProducts?.length > 0 &&
                    BundleProducts?.map((e) => {
                      return (
                        <li key={e.servRef}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div>
                              {popupText(e)}
                            </div>
                            <div>${(Number(e?.quantity) * getValidIntCost(getProductPrice(e, serviceTerm))).toFixed(2)}</div>
                          </div>
                        </li>
                      );
                    })}
                  <li>
                    <div
                      style={{ display: 'flex', justifyContent: 'space-between' }}
                      className="bundle-discount"
                    >
                      <div>Bundle Discount</div>
                      <div>
                        <b>(${Math.abs(Number(product?.creditAmount)).toFixed(2)})</b>
                      </div>
                    </div>
                  </li>
                </ul>
              }
            >
              <span>
                <InfoCircleOutlined />
              </span>
            </Popover>
          </p>
        </div>
        <ul className="bundle-list">
          {showAllIPBB ? (
            <li className="icon-list product-name">
              <div className="icons-list-details">
                <DashboardOutlined />
              </div>
              <p>
                <Select
                  className={`bundle-select ${isBundleAddedCart ? 'disable-bundle-text' : ''
                  }`}
                  bordered={false}
                  disabled={isBundleAddedCart}
                  size="small"
                  defaultValue={selectedInternet.servRef}
                  onChange={(value) =>
                    setSelectedInternet(
                      R.find(R.propEq('servRef', value), plans) ||
                      selectedInternet
                    )
                  }
                >
                  {plans.map((item) => {
                    return (
                      <Select.Option value={item.servRef} key={item.servRef}>
                        <div>
                          {item?.downstreamSpd / 1000}Mbps (${item.price})
                        </div>
                      </Select.Option>
                    );
                  })}
                </Select>
              </p>
            </li>
          ) : null}
          {showAllMob
            ? product?.productsServRef &&
              product?.productsServRef.map((i, ind) => {
                return (
                  <li key={`${i}-${ind}`} className="icon-list product-name">
                    <span className="icons-list-details">
                      <MobileOutlined />
                    </span>
                    <Select
                      className="bundle-select"
                      bordered={false}
                      size="small"
                      value={selectedMobServref.length ? selectedMobServref[ind] : mobileProducts[0].servRef}
                      onChange={value => handleMobileSelectChange(ind, value)}
                      disabled={isBundleAddedCart}
                    >
                      {mobileProducts.map((item) => {
                        return (
                          <Select.Option value={item.servRef} key={item.servRef}>
                            {item.serviceName} ({getProductPrice(item,serviceTerm)})
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </li>
                );
              })
            : null}
          {product.servBullet2 && product?.servBullet2?.toLowerCase() !== 'null' && (
            <li className="icon-list">
              <div className="icons-list-details">
                {getBulletIcon(product?.servBullet2)}
              </div>
              <p className="bundle-point">
                {product?.servBullet2 === product?.servBullet3 ? `(2) ${product?.servBullet2}` : product?.servBullet2}
              </p>
            </li>
          )}
          {product?.servBullet3 && product?.servBullet2 !== product?.servBullet3 &&
            <li className="icon-list">
              <div className="icons-list-details">
                {getBulletIcon(product?.servBullet3)}
              </div>
              <p>
                {product?.servBullet3}
              </p>
            </li>
          }
        </ul>
        <div>
          {!showCallNow ?
            <div className="down-cart d-flex al-center j-between">
              <button
                className={`blue-button ${isBundleAdded && 'grey-button'}`}
                disabled={isBundleAdded && !isBundleAddedCart}
                onClick={() => {
                  addBundleToCart();
                }}
              >
                {isBundleAddedCart ? 'Remove' : 'Add'}{' '}
                <i
                  className={`fa-solid ${isBundleAddedCart ? 'fa-times' : 'fa-solid fa-cart-shopping'
                  }`}
                ></i>
              </button>
              <div className="learnmore-total">
                <span
                  className="learnmore"
                  onClick={() => setIsDescriptionBoxOpened(product)}
                  onKeyDown={()=>{}}
                >
                  {isDescriptionBoxOpened === product?.bundleCode && isDescriptionBoxOpened !== '-1' ?
                    <span>See Less</span> :
                    <span>See More</span>}
                </span>
              </div>
            </div>
            : <RenderTfnButton />
          }
          {isDescriptionBoxOpened === product?.bundleCode && isDescriptionBoxOpened !== '-1' && (
            <div className="below-list">
              <ul className="list-bundle">
                <p className="learnmore-heading">Internet</p>
                {serviceDescPoints.map((point) => {
                  return <li className="icon-list" key={point}>{point}</li>;
                })}
                {BundleVasProduct ?
                  <> <p className="learnmore-heading">{BundleVasProduct?.techDetail}</p>
                    {BundleVasProduct?.serviceInfo?.split('.')?.filter(item => item !== '').map(point => <li key={point}>{point}</li>)}
                  </> : null}
                {BundleMobileProduct ?
                  <> <p className="learnmore-heading">Mobile</p>
                    {BundleMobileProduct?.serviceInfo?.split('.')?.filter(item => item !== '').map(point => <li key={point}>{point}</li>)}
                  </> : null}
              </ul>
            </div>
          )}
        </div>
      </div>
    </Col>
  )
}

export default BundlePlans
