import React, { useState, useMemo } from 'react';
import { Row } from 'antd';
import { useCallCenterFlag, useCartData } from '../../../hooks/order';
import BundlePlans from './BundlePlans';
import * as R from 'ramda';
import { getBundleProductPrice } from '../../../common/common';
const Bundles = (props) => {
  const { serviceabilityCheck, bundlePrices, setBundlePrices } = props;
  const { fixedWirelessProducts, plans, mobileProducts, vasProducts } = serviceabilityCheck;
  const { InternetBundles, MobileBundles, mobileProductServRef } = useCartData();
  const descriptionBoxClickHandler = (bundleProduct) => setIsDescriptionBoxOpened(isDescriptionBoxOpened === bundleProduct?.bundleCode ? '-1' : bundleProduct?.bundleCode);
  const [isDescriptionBoxOpened, setIsDescriptionBoxOpened] = useState('-1');
  const {showCallNow} = useCallCenterFlag()
  const allProducts = [...plans, ...mobileProducts, ...fixedWirelessProducts, ...vasProducts]
  const sortedInternetData = useMemo(() => {
    if (InternetBundles) {
      return R.sortBy(R.prop('singleProdDisplayOrder'), InternetBundles);
    }
  }, [InternetBundles]);

  const sortedMobileData = useMemo(() => {
    if (MobileBundles) {
      return R.sortBy(R.prop('multiProdDisplayOrder'), MobileBundles);
    }
  }, [MobileBundles]);

  const allBundles = [sortedInternetData, sortedMobileData]
  const showHeading = (i) => {
    if (i === 0 && sortedInternetData?.length !== 0) {
      return `Internet Bundle${sortedInternetData?.length > 1 ? 's' : ''} Available`;
    }
    if (i === 1 && sortedMobileData?.length !== 0) {
      return `Mobile Bundle${sortedMobileData?.length > 1 ? 's' : ''} Available`;
    }
  }

  return (
    <>
      {allBundles?.map((bundle, i) =>
        ( <>
          <div className={`plan-heading d-flex al-center ${showCallNow ? 'plan-heading-left' : ''} ${(i === 1 && sortedMobileData?.length !==0) ? 'mobile-heading': '' }`}>
            {bundle?.length > 0 &&
              <>
                <span className="y-icon">
                  <i className="fa-solid fa-wifi"></i>
                </span>{' '}
                <h3>
                  (<span>{bundle?.length}</span>) {showHeading(i)}
                </h3>
              </>}
          </div>
          <div className="all-single-plans">
            <Row>
              {bundle?.map((product, index) => {
                return (
                  <BundlePlans
                    key={product.bundleCode}
                    index={index}
                    plans={plans}
                    mobileProducts={mobileProducts}
                    product={product}
                    allProducts={allProducts}
                    bundlePrices={bundlePrices}
                    setBundlePrices={setBundlePrices}
                    setIsDescriptionBoxOpened={descriptionBoxClickHandler}
                    isDescriptionBoxOpened={isDescriptionBoxOpened}
                    mobileProductServRef={mobileProductServRef}
                    getProductPrice={getBundleProductPrice}
                  />
                )
              })}
            </Row>
          </div>
        </>)
      )}

    </>
  );
};
export default Bundles;
